import React, {
  FC,
  ReactNode,
  Ref,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";

import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./table.module.scss";
import { SORT_ORDERS, SORT_ORDER_VS_ICON } from "./table.constants";
import { getNextSortOrder } from "./table.helper";
import { orderBy } from "lodash-es";

const SortIcons: FC<{
  config: ITableConfig;
  onSortOrderChange: (order: SORT_ORDERS) => void;
  currentSort: string;
}> = ({ config, onSortOrderChange, currentSort }) => {
  const { sortKey, sortable } = config;

  const [sortOrder, setSortOrder] = useState<SORT_ORDERS>(SORT_ORDERS.NONE);

  const onSortOrderClick = () => {
    const nextOrder = getNextSortOrder(sortOrder);
    setSortOrder(nextOrder);

    onSortOrderChange(nextOrder);
  };

  if (!sortable) return null;
  return (
    <div className={styles.sort_icons_wrapper} onClick={onSortOrderClick}>
      <FontAwesomeIcon
        className={styles.sort_icons}
        icon={
          config.accessor === currentSort
            ? SORT_ORDER_VS_ICON[sortOrder]
            : SORT_ORDER_VS_ICON["NONE"]
        }
      />
    </div>
  );
};

const Table: FC<ITable> = (props) => {
  const {
    rowClassName,
    tableData,
    tableConfig,
    draggable,
    searchKeys,
    tableHeader,
  } = props;

  const [curretSortAccessor, setCurretSortAccessor] = useState("");
  const [curretSortOrder, setCurretSortOrder] = useState<SORT_ORDERS>(
    SORT_ORDERS.NONE
  );
  const [tableDataState, setTableDataState] = useState<Array<any>>([]);
  const [tableConfigState, setTableConfigState] = useState<Array<ITableConfig>>(
    []
  );
  const [searchVal, setSearchVal] = useState("");

  const searchAndSortRowData = (
    _sortOrder: SORT_ORDERS = curretSortOrder,
    _searchVal: string = searchVal
  ) => {
    const data = searchRows(_searchVal);
    if (_sortOrder === SORT_ORDERS.NONE) {
      setTableDataState(data);
    }
    if (_sortOrder === SORT_ORDERS.ASC) {
      setTableDataState(orderBy(data, [curretSortAccessor], ["asc"]));
    }
    if (_sortOrder === SORT_ORDERS.DSC) {
      setTableDataState(orderBy(data, [curretSortAccessor], ["desc"]));
    }
  };

  const hanldeSortClick =
    (_headerConfig: ITableConfig) => (_sortOrder: SORT_ORDERS) => {
      setCurretSortAccessor(_headerConfig.accessor || "");
      setCurretSortOrder(_sortOrder);
      searchAndSortRowData(_sortOrder);
    };

  const renderHeader = () => {
    const _headers = (tableConfigState || []).map((config) => {
      const { header, headerClassName, thProps } = config;
      return (
        <th {...thProps} className={[styles.th, headerClassName].join(" ")}>
          <div className={styles.th_content_wrapper}>
            {header}
            <SortIcons
              config={config}
              onSortOrderChange={hanldeSortClick(config)}
              currentSort={curretSortAccessor}
            />
          </div>
        </th>
      );
    });
    return (
      <thead>
        <tr>
          {draggable?.row && <th />}
          {_headers}
        </tr>
      </thead>
    );
  };

  const renderRows = (row: any) => {
    const ComponentToRender = React.Fragment;
    return (
      <ComponentToRender>
        <tr className={rowClassName}>
          {draggable?.row && (
            <td>
              <FontAwesomeIcon className="handle" icon={faGripVertical} />
            </td>
          )}
          {(tableConfigState || []).map(renderCells(row))}
        </tr>
      </ComponentToRender>
    );
  };

  const renderCells = (rowData: any) => (tConfig: any) => {
    const {
      cellRenderer: CellRenderer,
      cellClassName,
      accessor,
      tdProps,
    } = tConfig;
    return (
      <td
        {...tdProps}
        className={[
          styles.td,
          typeof cellClassName === "function"
            ? cellClassName(rowData[accessor] || undefined, rowData)
            : cellClassName,
        ].join(" ")}
      >
        {typeof CellRenderer === "function"
          ? CellRenderer(rowData)
          : rowData[accessor]}
      </td>
    );
  };

  const renderTBody = () => {
    const _rows = (tableDataState || []).map(renderRows);
    return (
      <tbody>
        {_rows.length ? (
          _rows
        ) : (
          <tr>
            <td
              colSpan={tableConfigState.length + 1}
              align="center"
              style={{ padding: "1rem" }}
            >
              Not Record Found
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  useEffect(() => {
    setTableDataState(tableData);
  }, [tableData]);

  useEffect(() => {
    setTableConfigState(tableConfig);
  }, [tableConfig]);

  const searchRows: (val?: string) => Array<any> = (val) => {
    if (val) {
      const matchingRows = tableData.filter((data) => {
        if (searchKeys?.length) {
          for (let i = 0; i < searchKeys.length; i++) {
            const regex = new RegExp("^" + val, "i");
            const keyVal = data[searchKeys[i]];
            if (regex.test(keyVal)) return data;
          }
        }
      });
      return matchingRows;
    }
    return tableData;
  };
  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const val = e?.currentTarget?.value;
    setSearchVal(val);
    searchAndSortRowData(curretSortOrder, val);
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.table_actions}>
          <div>{tableHeader}</div>
          <input
            disabled={!searchKeys?.length}
            placeholder="Search"
            type={"search"}
            onChange={handleSearch}
            value={searchVal}
          />
        </div>
        <table className={styles.table}>
          {renderHeader()}
          {renderTBody()}
        </table>
      </div>
    </div>
  );
};

export interface ITableConfig {
  header: ReactNode | string;
  cellRenderer?: (rowData: any) => ReactNode;
  headerClassName?: string;
  cellClassName?: string;
  sortable?: boolean;
  sortKey?: boolean;
  hide?: boolean;
  accessor?: string;
  thProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  >;
  tdProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  >;
}
export interface ITable {
  tableHeader?: ReactNode;
  tableConfig: ITableConfig[];
  rowClassName?: string;
  className?: string;
  tableData: Array<any>;
  resizeable?: boolean;
  searchKeys?: string[];
  draggable?: {
    row: boolean;
    column: boolean;
  };
}

export default Table;
