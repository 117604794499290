import React from "react";
import { faCross, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./style.css";

const PrivacyPolicy = () => {
  return (
    <div className="modal_background">
      <div className="modal_container">
        <div className={"close"}>
          <Link to="login">
            Close
            <FontAwesomeIcon icon={faTimes} size="lg" />{" "}
          </Link>
        </div>
        <div className="modal_content">
          <h3>REFUND POLICY</h3>
          <p>
            The payment(s) made by you on TIEDN APP towards the outstanding/
            collection for the supply of NewsPaper / Magazine is non-refundable.
            You do not have any right to claim for refund your payments in whole
            or any part. Although you may notify us of your reason for
            requesting for refund at any time by notifying our team at&nbsp;
            <a href="mailto:tiednmumbai@expressindia.com">
              tiednmumbai@expressindia.com
            </a>{" "}
            , however you will not receive a refund (except in the limited
            circumstances as we deem fit). We use third party payment gateway
            services for receiving payment from you and you agree to make
            payment using these third-party payment gateway.
          </p>
          <div className="hide_modal_btn">
            {/* <button onClick={()=>{closeModal(false)}}>CLOSE</button> */}
            <Link to="login">To Login Page</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
