import {
  faArrowDown,
  faArrowUp,
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";

export enum SORT_ORDERS {
  ASC = "ASC",
  DSC = "DSC",
  NONE = "NONE",
}

export const SORT_ORDER_VS_ICON = {
  [SORT_ORDERS.ASC]: faArrowUp,
  [SORT_ORDERS.DSC]: faArrowDown,
  [SORT_ORDERS.NONE]: faSort,
};
