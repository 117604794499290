import axios from "axios";
import useAuthStore from "./stores";

export const axiosInstance = axios.create({
  baseURL: `https://dmsapi.expressindia.com`,
  // baseURL: `http://a268-2409-4043-2d17-d79a-4115-bf1e-509d-b2ae.ngrok.io`,
});

axiosInstance.interceptors.request.use((conf) => {
  if (localStorage.getItem("login_token")) {
    const token = localStorage.getItem("login_token") || "";
    conf.headers = {
      ...conf.headers,
      Accept: "application/vnd.tiedn.ie.api.v1+json",
      "Content-Type": "application/vnd.tiedn.ie.api.v1+json",
      Authorization: token,
    };
  }

  return conf;
});
