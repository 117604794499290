import React, { FC } from "react";
import { ROLE_VS_PERMISSIONS, WEB_ROLES } from "../../../constants/roles";
import { useFormState } from "../../../hooks";
import Button from "../../base/Button";
import Input from "../../base/Input";
import Select from "../../base/Select";

const CollectionForm: FC<{
  value: any;
  onSubmit: (state: any) => void;
  role: WEB_ROLES;
}> = ({ onSubmit, value, role }) => {
  const [formState, handleInputChange, resetState, setInitialState, dirty] =
    useFormState(value);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const newState = {
          ...formState,
          outstanding: formState.closing_balance,
        };
        delete formState.closing_balance;
        onSubmit(newState);
      }}
    >
      <div style={{ display: "flex" }}>
        <Input
          disabled
          value={`${formState.parcel_depot_name} (${formState.ship_to_code}-${formState.user_name})`}
          label="Depot"
          style={{ flex: 1 }}
          name="depot"
          onChange={() => {}}
        />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ flex: 1 }}>
          <Input
            disabled
            label="Bill To Date"
            style={{ flex: 1 }}
            type={"date"}
            name="bill_till_date"
            value={formState.bill_till_date}
            onChange={() => {}}
          />
        </div>
        <p></p>
      </div>
      <div>
        <Input
          disabled
          label="Payment Mode"
          style={{ flex: 1 }}
          name="payment_mode"
          value={formState.payment_mode}
          onChange={() => {}}
        />
      </div>
      {ROLE_VS_PERMISSIONS[role]?.outstanding_collection?.view && (
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flex: 1, marginRight: "1rem" }}>
            <Input
              disabled={
                !ROLE_VS_PERMISSIONS[role]?.outstanding_collection?.edit
              }
              label="Outstanding"
              style={{ flex: 1 }}
              name="closing_balance"
              value={formState.closing_balance}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Input
            type={"number"}
              disabled={!ROLE_VS_PERMISSIONS[role]?.amount_collected?.edit}
              value={formState.amount_collected}
              label="Amount Collected"
              style={{ flex: 1 }}
              name="amount_collected"
              min={0}
              onChange={handleInputChange}
            />
          </div>
        </div>
      )}
      <div
        style={{
          marginTop: "2rem",
        }}
      >
        <Button type="submit" disabled={!dirty}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default CollectionForm;
