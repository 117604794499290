import {
  faChevronRight,
  faCheck,
  faTimes,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import styles from "./select.module.scss";

interface X {
  multi: false;
  onChange: (valObj: { [key: string]: string }, id: string, op: any) => void;
  selectedId?: string;
}

interface Y {
  multi: true;
  onChange: (
    valObj: { [key: string]: string[] },
    id: string[],
    op: any
  ) => void;
  selectedId?: string[];
}
interface ISelect {
  label?: ReactNode;
  multi?: boolean;
  selectedOption?: any;
  options: Array<any>;
  optionAccessor: string;
  placeholder?: string;
  className?: string;
  name?: string;
  disabled?: boolean;
  onChange: (
    valObj: { [key: string]: string | string[] },
    id: string | string[],
    op: any
  ) => void;
  selectedId?: string | string[];
  loading?: boolean;
}

const Select: FC<ISelect> = ({
  multi = false,
  label,
  selectedId,
  selectedOption,
  options,
  optionAccessor,
  placeholder = "Select",
  className = "",
  name,
  disabled,
  onChange,
  loading = false,
}) => {
  const [optionsState, setOptions] = useState<any[]>();
  const [showOptions, setShowOptions] = useState<Boolean>();
  const [selectedOptionsState, setSelectedOptions] = useState<any[]>([]);

  const handleShowOption = () => {
    if (disabled) return;
    setShowOptions(true);
  };

  const handleHideOption = () => {
    setShowOptions(false);
  };

  const hasId = (op: any) =>
    selectedOptionsState.find((_op) => _op.id === op.id);

  const handleOptionSelect = (op: any) => () => {
    let newOptions = [...selectedOptionsState, op];
    if (hasId(op))
      newOptions = selectedOptionsState.filter((_op) => _op.id !== op.id);

    if (multi) {
      onChange(
        {
          [name || ""]: newOptions.map((op) => op.id),
        },
        newOptions.map((op) => op.id),
        newOptions
      );
    } else {
      onChange({ [name || ""]: op.id }, op.id, op);
      setShowOptions(false);
    }
    setSelectedOptions(newOptions);
  };
  const renderOptions = () => {
    if (optionsState?.length) {
      return optionsState.map((op) => {
        return (
          <li
            key={op.id}
            className={hasId(op) ? styles.active : undefined}
            onClick={handleOptionSelect(op)}
          >
            {hasId(op) && (
              <section>
                <FontAwesomeIcon size="xs" icon={faCheck} />
              </section>
            )}
            {op[optionAccessor]}
          </li>
        );
      });
    }
    return (
      <div style={{ height: "4rem", textAlign: "center" }}>No Options</div>
    );
  };

  useEffect(() => {
    if (options) setOptions(options);
  }, [options]);

  useEffect(() => {
    if (selectedOption) {
      if (Array.isArray(selectedOption)) setSelectedOptions(selectedOption);
      else setSelectedOptions([selectedOption]);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedId) {
      let id = selectedId;
      if (!Array.isArray(selectedId)) id = [selectedId];
      const selectedOp = options?.filter((op) => id.includes(op.id));

      setSelectedOptions(selectedOp);
    } else {
      setSelectedOptions([]);
    }
  }, [selectedId, options]);

  const renderClips = () => {
    return (
      <ul className={styles.clip_wrapper}>
        {selectedOptionsState.map((op) => {
          return (
            <li>
              {op[optionAccessor] || ""}{" "}
              <FontAwesomeIcon
                size="sm"
                icon={faTimes}
                onClick={(e) => {
                  e.stopPropagation();
                  const newOptions = selectedOptionsState.filter(
                    (_op) => _op.id !== op.id
                  );
                  onChange(
                    {
                      [name || ""]: newOptions.map((op) => op.id),
                    },
                    newOptions.map((op) => op.id),
                    newOptions
                  );
                  setSelectedOptions(newOptions);
                }}
              />
            </li>
          );
        })}
      </ul>
    );
  };

  const renderLabelSection = () => {
    if (multi) {
      if (selectedOptionsState.length) return renderClips();
      return placeholder;
    }
    return selectedOptionsState[0] && selectedOptionsState[0][optionAccessor]
      ? selectedOptionsState[0][optionAccessor]
      : placeholder;
  };

  return (
    <div className={[styles.select_wrapper, className].join(" ")}>
      {label && <label>{label}</label>}
      <div
        className={[
          styles.label_area,
          disabled && styles.disabled,
          loading && styles.disabled,
          multi && styles.multi,
        ].join(" ")}
        onClick={handleShowOption}
      >
        {renderLabelSection()}
        {loading ? (
          <FontAwesomeIcon
            className={styles.loading_spinner}
            size="xs"
            icon={faSpinner}
          />
        ) : (
          <FontAwesomeIcon size="xs" icon={faChevronRight} />
        )}
      </div>
      {showOptions && (
        <>
          <div className={styles.backdrop} onClick={handleHideOption}></div>
          <section className={styles.options_wrapper}>
            <ul>{renderOptions()}</ul>
          </section>
        </>
      )}
    </div>
  );
};

export default Select;
