import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, memo, useEffect, useState } from "react";
import { ROLES, USER_TYPE } from "../../../constants/roles";
import { useFormState, useQuery } from "../../../hooks";
import { getCenters, getRegions } from "../../../state/actions/masters.action";
import {
  createUser,
  fetchUsers,
  getCenterOrRegionCode,
  IUser,
  updateUser,
} from "../../../state/actions/user.actions";
import Button from "../../base/Button";
import Input from "../../base/Input";
import Modal from "../../base/Modal";
import PageHeader from "../../base/PageHeader";
import Select from "../../base/Select";
import Table from "../../helper/Table";
import { ITableConfig } from "../../helper/Table/Table";
import styles from "./userPage.module.scss";

const UserForm: FC<{ onSubmit: (formState: any) => void; value: any }> = ({
  onSubmit,
  value,
}) => {
  const [formState, handleInputChange, resetState, setInitialState, dirty] =
    useFormState(value);

  const { exec: execRegion, data: regionData } = useQuery();
  const { exec: execCenter, data: centerData } = useQuery();
  const { exec: execCode, data: code } = useQuery();

  useEffect(() => {
    const promiseArr = [
      execCenter(() => getCenters()),
      execRegion(() => getRegions()),
    ];
    if (value.id)
      promiseArr.push(
        execCode(() => getCenterOrRegionCode(value.id, value.roles))
      );

    Promise.all(promiseArr).then((d) => {});
  }, [value]);

  useEffect(() => {
    if (code)
      handleInputChange({
        add_update_location_pk_id: code,
      });
  }, [code]);

  const getRegionOrCenters = () => {
    return (
      (formState.roles === "Circulation Executive" ||
      formState.roles === "Collection Executive" ||
      formState.roles === "Parcel Vendor" ||
      formState.roles === "Depot Salesman"
        ? centerData
        : regionData) || []
    ).map(({ id, name }: { id: string; name: string }) => ({
      label: name,
      id: id,
    }));
  };

  return (
    <form
      style={{ width: "30rem" }}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(formState);
      }}
    >
      <div>
        <Input
          required
          label="Name"
          name="name"
          value={formState.name}
          onChange={handleInputChange}
        />
      </div>
      <div style={{ display: "flex" }}>
        <Input
          required
          minLength={8}
          label="Login ID"
          name="login_id"
          value={formState.login_id}
          onChange={handleInputChange}
        />
        <Input
          required
          type="password"
          minLength={8}
          label="Password"
          name="password"
          placeholder="*****"
          value={formState.password}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <Input
          required
          label="Email"
          name="email"
          value={formState.email}
          type={"email"}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <Select
          // disabled={}
          name="roles"
          selectedId={formState.roles}
          label="Role"
          optionAccessor="label"
          onChange={(valObj) => {
            if (valObj.roles === "Admin") {
              handleInputChange({
                ...valObj,
                add_update_location_pk_id: regionData.map(
                  (region: any) => region.id
                ),
              });
            } else handleInputChange(valObj);
          }}
          options={
            !formState.id
              ? ROLES.filter(
                  (role) =>
                    role !== "Parcel Vendor" && role !== "Depot Salesman"
                ).map((key) => ({
                  label: key,
                  id: key,
                }))
              : ROLES.map((key) => ({
                  label: key,
                  id: key,
                }))
          }
        />
      </div>
      <div>
        <Select
          multi={
            formState.roles !== "Depot Salesman" &&
            formState.roles !== "Parcel Vendor"
          }
          disabled={!formState.roles}
          name="add_update_location_pk_id"
          selectedId={formState.add_update_location_pk_id || ""}
          label={
            !formState.roles
              ? "Center / Region"
              : formState.roles === "Circulation Executive" ||
                formState.roles === "Collection Executive" ||
                formState.roles === "Depot Salesman" ||
                formState.roles === "Parcel Vendor"
              ? "Center"
              : "Region"
          }
          optionAccessor="label"
          onChange={handleInputChange}
          options={!formState.roles ? [] : getRegionOrCenters()}
        />
      </div>
      <div>
        <Input
          required
          maxLength={10}
          label="Phone"
          name="phone"
          value={formState.phone}
          type={"tel"}
          onChange={handleInputChange}
        />
      </div>
      <div
        style={{
          marginTop: "2rem",
        }}
      >
        <Button type="submit" disabled={!dirty}>
          {formState.id ? "Update" : "Create"}
        </Button>
      </div>
    </form>
  );
};

const UserPage = () => {
  const { exec, data } = useQuery();
  const { exec: execUpdate, loading } = useQuery();
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<null | any>(null);
  const tableConfig: ITableConfig[] = [
    {
      header: "User ID",
      accessor: "id",
      sortable: true,
    },
    {
      header: "Name",
      accessor: "name",
      sortable: true,
    },
    {
      header: "Login ID",
      accessor: "login_id",
      sortable: true,
    },
    {
      header: "Email",
      accessor: "email",
      sortable: true,
    },
    {
      header: "Role",
      accessor: "roles",
      sortable: true,
    },
    {
      header: "Phone",
      accessor: "phone",
      sortable: true,
    },
    {
      header: "action",
      headerClassName: styles.right,
      cellClassName: styles.right,
      cellRenderer: (data) => {
        return (
          <FontAwesomeIcon
            className={styles.editIcon}
            icon={faPen}
            onClick={() => {
              setSelectedUser(data);
              setShowModal(!showModal);
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    exec(() => fetchUsers());
  }, []);

  const handleUserUpdate = (data: IUser) => {
    const funcToExec = selectedUser ? updateUser : createUser;
    execUpdate(() =>
      funcToExec({
        id: data.id,
        email: data.email,
        login_id: data.login_id,
        name: data.name,
        password: data.password,
        phone: data.phone,
        roles: data.roles,
        type: USER_TYPE.internal,
        add_update_location_pk_id:
          typeof data.add_update_location_pk_id === "number"
            ? [data.add_update_location_pk_id]
            : data.add_update_location_pk_id,
      }).then(() => {
        exec(() => fetchUsers());
        closeModal();
      })
    );
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  const openCreateUserModal = () => {
    setShowModal(true);
    setSelectedUser(null);
  };

  return (
    <div>
      <PageHeader
        header="Users"
        breadCrumb={["Dashboard", "Users"]}
        rightSection={
          <Button onClick={openCreateUserModal}> Create User</Button>
        }
      />
      <Table
        tableConfig={tableConfig}
        tableData={data}
        searchKeys={["name", "phone", "email", "login_id"]}
      />
      <Modal
        header={selectedUser ? <h3>Update User</h3> : <h3>Create User</h3>}
        show={showModal}
        onClose={closeModal}
        loading={loading}
      >
        <UserForm value={selectedUser || {}} onSubmit={handleUserUpdate} />
      </Modal>
    </div>
  );
};

export default memo(UserPage);
