import React, { useState } from "react";
import Button from "../../base/Button";
import Input from "../../base/Input";
import styles from "./login.module.scss";
import logo from "../../../assets/icons/logo_black.svg";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor } from "@mui/material/Alert";
import { useFormState } from "../../../hooks";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../../state/actions/auth.action";
import Select from "../../base/Select";

export const Login = () => {
  const [showSnack, setShowSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>("success");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const [formState, handleInputChange] = useFormState({
    login_id: "",
    password: "",
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    login(formState)
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        setShowSnack(true);
        setSnackSeverity("error");
        setMsg(err?.response ? "Invalid Credentials" : err.message);
      });
  };

  const handleClose = () => {
    setShowSnack(false);
    setSnackSeverity("info");
    setMsg("");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={logo} loading="lazy" />
      </div>
      <div className={styles.cardWrapper}>
        <div className={styles.card_warpper_header}>
          <h3>Login</h3>
          <p>To continue, log in to TIEDN Portal.</p>
        </div>
        <form className={styles.login_form} onSubmit={handleSubmit}>
          <Input
            label="Login ID"
            placeholder="Enter Login ID"
            onChange={handleInputChange}
            name="login_id"
          />
          <Input
            type="password"
            label="Password"
            placeholder="Enter Password"
            onChange={handleInputChange}
            name="password"
          />

          <div className={styles.submit_row}>
            <div className={styles.forgot_password_wrapper}>
              <a>Forgot Your Password ?</a>
              <div>
                <input id="remember" type={"checkbox"} />
                <label htmlFor="remember">Remember Me</label>
              </div>
            </div>
            <Button>Login</Button>
          </div>
        </form>
      </div>
      <div className={styles.footer}>
        <div className={styles.left}>
          Copyright Reserved with The Indian Express.
        </div>
        <div className={styles.right}>
          <Link to="/about-us">About Us</Link>
          <Link to="/contact-us">Contact Us</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms">Terms & Condition</Link>
          <Link to="/refund-policy">Refund Policy</Link>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={snackSeverity}
        >
          {msg}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};
