import { axiosInstance } from "../axios";
import useAuthStore from "../stores";

export enum STATUS {
  PENDING = "_0",
  APPROVE = "_1",
  REJECT = "_2",
}

export const getUnsoldReturnList = (from: Date, to: Date, userId?: string) => {
  return axiosInstance
    .get("/transaction/unsold-return", {
      // params: { userId: useAuthStore.getState().user?.id },
      params: {
        // userId: 1,
        isFilter: !!userId,
        userId: userId || useAuthStore.getState().user?.id,
        startDate: from.toISOString().split("T")[0],
        endDate: to.toISOString().split("T")[0],
      },
    })
    .then(({ data }) => {
      return data.data;
    });
};

export const getCollectionList = (from: Date, to: Date, userId?: string) => {
  return axiosInstance
    .get("/transaction/collection", {
      // params: { userId: useAuthStore.getState().user?.id },
      params: {
        isFilter: !!userId,
        userId: userId || useAuthStore.getState().user?.id,
        startDate: from.toISOString().split("T")[0],
        endDate: to.toISOString().split("T")[0],
      },
    })
    .then(({ data }) => {
      return data.data;
    });
};

export const updateUnsoldReturns = (payload: any) => {
  return axiosInstance
    .post("/transaction/unsold-return", {
      ...payload,
      updated_by_last_user_id: useAuthStore.getState().user?.id,
      approval_status: "_0",
    })
    .then(({ data }) => {
      return data.data;
    });
};
export const updateCollection = (payload: any) => {
  return axiosInstance
    .post("/transaction/collection", {
      ...payload,
      updated_by_last_user_id: useAuthStore.getState().user?.id,
    })
    .then(({ data }) => {
      return data.data;
    });
};

export const updateRecordStatus = (payload: {
  id: string | number;
  module: "unsold_return" | "collection";
  approval_status: STATUS;
}) => {
  return axiosInstance
    .post("/transaction/approval-status", {
      ...payload,
      user_id: useAuthStore.getState().user?.id,
    })
    .then(({ data }) => {
      return data.data;
    });
};
