import React, { useEffect } from "react";
import { useQuery } from "../../../hooks";
import { getCities } from "../../../state/actions/masters.action";
import PageHeader from "../../base/PageHeader";
import Table from "../../helper/Table";
import { ITableConfig } from "../../helper/Table/Table";

const config: ITableConfig[] = [
  {
    header: "ID",
    accessor: "id",
    sortable: true,
  },

  {
    header: "Name",
    accessor: "name",
    sortable: true,
  },
];
const CitiesPage = () => {
  const { exec, data } = useQuery();
  useEffect(() => {
    exec(() => getCities());
  }, []);
  return (
    <>
      <PageHeader
        header="Cities"
        breadCrumb={["Dashboard", "Master", "Cities"]}
        // rightSection={<Button>Add Publication</Button>}
      />
      <Table tableData={data} tableConfig={config} searchKeys={["name"]} />
    </>
  );
};

export default CitiesPage;
