import React, { FC } from "react";
import { ROLE_VS_PERMISSIONS, WEB_ROLES } from "../../../constants/roles";
import { useFormState } from "../../../hooks";
import Button from "../../base/Button";
import Input from "../../base/Input";
import Select from "../../base/Select";

const UnsoldForm: FC<{
  value: any;
  onSubmit: (state: any) => void;
  role: WEB_ROLES;
}> = ({ onSubmit, value, role }) => {
  const [formState, handleInputChange, resetState, setInitialState, dirty] =
    useFormState(value);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(formState);
      }}
    >
      <div>
        <Input
          disabled
          label="From"
          style={{ flex: 1 }}
          type={"date"}
          name="publication_date"
          onChange={() => {}}
          value={formState.publication_date}
        />
      </div>
      <div style={{ display: "flex" }}>
        <Input
          disabled
          label="Publication"
          style={{ flex: 1 }}
          name="publication_name"
          value={formState.publication_name}
          onChange={() => {}}
        />
      </div>
      <div style={{ display: "flex" }}>
        <Input
          disabled
          label="Depot"
          style={{ flex: 1 }}
          name="depot"
          onChange={() => {}}
          value={`${formState.parcel_depot_name} (${formState.ship_to_code}-${formState.user_name})`}
        />
      </div>

      {ROLE_VS_PERMISSIONS[role]?.supply?.view && (
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flex: 1 }}>
            <Input
              disabled={!ROLE_VS_PERMISSIONS[role]?.supply?.edit}
              value={formState.total_supply}
              label="Supply"
              style={{ flex: 1 }}
              name="total_supply"
              onChange={handleInputChange}
            />
          </div>
        </div>
      )}

      {ROLE_VS_PERMISSIONS[role]?.unsold_return?.edit && (
        <div style={{ display: "flex", width: "100%" }}>
          <Input
            disabled={!ROLE_VS_PERMISSIONS[role]?.unsold_return?.edit}
            label="Unsold"
            style={{ flex: 1 }}
            name="unsold"
            value={formState.unsold}
            onChange={handleInputChange}
          />
          <Input
            disabled={!ROLE_VS_PERMISSIONS[role]?.unsold_return?.edit}
            label="Returns"
            style={{ flex: 1 }}
            name="supply_return"
            value={formState.supply_return}
            onChange={handleInputChange}
          />
        </div>
      )}

      {ROLE_VS_PERMISSIONS[role]?.physical_return.edit && (
        <div style={{ display: "flex", width: "100%" }}>
          <Input
            label="Physical Returns"
            style={{ flex: 1 }}
            name="physical_return"
            value={formState.physical_return}
            onChange={handleInputChange}
          />
        </div>
      )}
      {ROLE_VS_PERMISSIONS[role]?.nps && (
        <div style={{ display: "flex", width: "100%" }}>
          <Input
            disabled
            label="NPS"
            style={{ flex: 1 }}
            name="nps"
            value={
              parseFloat(formState.total_supply || 0) -
              parseFloat(formState.unsold || 0) -
              parseFloat(formState.supply_return || 0)
            }
            onChange={() => {}}
          />
        </div>
      )}
      <div
        style={{
          marginTop: "2rem",
        }}
      >
        <Button type="submit" disabled={!dirty}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default UnsoldForm;
