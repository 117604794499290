import React, { useEffect } from "react";
import { useQuery } from "../../../hooks";
import { getCenters } from "../../../state/actions/masters.action";
import PageHeader from "../../base/PageHeader";
import Table from "../../helper/Table";
import { ITableConfig } from "../../helper/Table/Table";

const config: ITableConfig[] = [
  {
    header: "ID",
    accessor: "id",
  },

  {
    header: "Name",
    accessor: "name",
  },
];
const CentersPage = () => {
  const { exec, data } = useQuery();
  useEffect(() => {
    exec(() => getCenters());
  }, []);
  return (
    <>
      <PageHeader
        header="Centers"
        breadCrumb={["Dashboard", "Master", "Centers"]}
        // rightSection={<Button>Add Publication</Button>}
      />
      <Table tableData={data} tableConfig={config} searchKeys={["name"]} />
    </>
  );
};

export default CentersPage;
