import React, { useEffect } from "react";
import { useQuery } from "../../../hooks";
import { getRegions } from "../../../state/actions/masters.action";
import PageHeader from "../../base/PageHeader";
import Table from "../../helper/Table";
import { ITableConfig } from "../../helper/Table/Table";

const config: ITableConfig[] = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "Name",
    accessor: "name",
  },
  {
    header: "Description",
    accessor: "description",
  },
];

const RegionsPage = () => {
  const { exec, data } = useQuery();
  useEffect(() => {
    exec(() => getRegions());
  }, []);
  return (
    <>
      <PageHeader
        header="Regions"
        breadCrumb={["Dashboard", "Master", "Regions"]}
        // rightSection={<Button>Add Publication</Button>}
      />
      <Table
        tableData={data}
        tableConfig={config}
        searchKeys={["name", "description"]}
      />
    </>
  );
};

export default RegionsPage;
