import React, { FC, ReactNode } from "react";
import styles from "./pageHeader.module.scss";

export interface IPageHeader {
  header: string | ReactNode;
  breadCrumb: Array<string>;
  rightSection?: ReactNode;
  subHeader?: ReactNode;
}
const PageHeader: FC<IPageHeader> = ({
  header,
  rightSection,
  breadCrumb,
  subHeader,
}) => {
  return (
    <div className={styles.wrapper}>
      <section className={styles.left}>
        <h2>{header}</h2>
        {subHeader && subHeader}
        <div className={styles.break_crumbs}>
          {breadCrumb.map((str, i) =>
            i === breadCrumb.length - 1 ? (
              <strong key={i}>{str}</strong>
            ) : (
              <span key={i}>{str} / </span>
            )
          )}
        </div>
      </section>
      <section className={styles.right}>{rightSection}</section>
    </div>
  );
};

export default PageHeader;
