import {
  faAngleDown,
  faAngleRight,
  faGripHorizontal,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, ReactNode, useEffect, useState } from "react";
import styles from "./sideNav.module.scss";
import logo from "../../../assets/icons/logo.svg";
// import reportIcon from "../../../assets/icons/newspaper.png";
import dashboardIcon from "../../../assets/icons/dashboard-1@2x.png";

import { useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../../../state/stores";
import { ROLES, WEB_ROLES } from "../../../constants/roles";

interface ILiItem {
  title: string | ReactNode;
  onClick?: () => void;
  path?: string;
  icon?: IconDefinition;
  iconImage?: string;
  subItems?: Array<ILiItem>;
}
const LiComp: FC<ILiItem> = ({
  title,
  subItems,
  path,
  onClick,
  icon,
  iconImage,
}) => {
  const loc = useLocation();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const haveSubMenu = (subItems || [])?.length > 0;

  const renderSubMenu = () => {
    if (haveSubMenu && expanded)
      return (
        <ul>
          {subItems?.map((item) => (
            <LiComp {...item} />
          ))}
        </ul>
      );
  };

  const handleClick = () => {
    if (path) {
      navigate(path);
    }
    if (onClick) {
      onClick();
    }
  };

  const renderIcon = () => {
    if (icon)
      return (
        <FontAwesomeIcon
          icon={faGripHorizontal}
          style={{ marginRight: 12, fontSize: "1.4rem" }}
        />
      );
    if (iconImage) return <img src={iconImage} />;
  };

  const liClass = [];

  if (haveSubMenu) liClass.push(styles.nested);
  if (expanded) liClass.push(styles.open);

  if (haveSubMenu && subItems?.find((p) => p.path === loc.pathname)) {
    liClass.push(styles.open);
  }

  if (loc.pathname === path) liClass.push(styles.active);

  useEffect(() => {
    if (haveSubMenu && subItems?.find((p) => p.path === loc.pathname)) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [loc.pathname]);

  return (
    <li className={liClass.join(" ")} onClick={handleClick}>
      <div>
        {renderIcon()}
        <text>{title}</text>
        {haveSubMenu && (
          <FontAwesomeIcon
            icon={expanded ? faAngleDown : faAngleRight}
            style={{ marginRight: 12, fontSize: "1.4rem" }}
            onClick={() => setExpanded(!expanded)}
            className={styles.expandArrow}
          />
        )}
      </div>
      {renderSubMenu()}
    </li>
  );
};

const SideNav = () => {
  const { user } = useAuthStore();

  const role: WEB_ROLES = user?.role;

  const list: Array<ILiItem> = [
    { title: "Dashboard", iconImage: dashboardIcon, path: "/dashboard" },
    ...(role === WEB_ROLES["Regional Manager"]
      ? [
          {
            title: "Users Attendance",
            iconImage: dashboardIcon,
            path: "/dashboard/user-attandance",
          },
          {
            title: "Reports",
            iconImage: dashboardIcon,
            path: "/dashboard/reports",
          },
        ]
      : []),
    ...(role === WEB_ROLES.Admin
      ? [
          {
            title: "Users",
            iconImage: dashboardIcon,
            path: "/dashboard/users",
          },
          {
            title: "Users Attendance",
            iconImage: dashboardIcon,
            path: "/dashboard/user-attandance",
          },
          {
            title: "Reports",
            iconImage: dashboardIcon,
            path: "/dashboard/reports",
          },
          {
            title: "UnMapped Users",
            iconImage: dashboardIcon,
            path: "/dashboard/master/unmappedusers",
          },
          /* {
            title: "Masters",
            iconImage: dashboardIcon,
            subItems: [
              {
                title: "States",
                iconImage: dashboardIcon,
                path: "/dashboard/master/states",
              },
              {
                title: "Cities",
                iconImage: dashboardIcon,
                path: "/dashboard/master/cities",
              },
              {
                title: "Districts",
                iconImage: dashboardIcon,
                path: "/dashboard/master/districts",
              },
              {
                title: "Regions",
                iconImage: dashboardIcon,
                path: "/dashboard/master/regions",
              },
              {
                title: "Centers",
                iconImage: dashboardIcon,
                path: "/dashboard/master/centers",
              },
              {
                title: "UnMapped Users",
                iconImage: dashboardIcon,
                path: "/dashboard/master/unmappedusers",
              },
            ],
          }, */
        ]
      : []),
    ...(role === WEB_ROLES["Collection Head"]
      ? [
          {
            title: "Users Attendance",
            iconImage: dashboardIcon,
            path: "/dashboard/user-attandance",
          },
          {
            title: "Reports",
            iconImage: dashboardIcon,
            path: "/dashboard/reports",
          },
        ]
      : []),
    ...(role === WEB_ROLES["Collection Backend Executive"]
      ? [
          {
            title: "Users Attendance",
            iconImage: dashboardIcon,
            path: "/dashboard/user-attandance",
          },
          {
            title: "Reports",
            iconImage: dashboardIcon,
            path: "/dashboard/reports",
          },
        ]
      : []),
    ...(role === WEB_ROLES["Operation Head"]
      ? [
          {
            title: "Users Attendance",
            iconImage: dashboardIcon,
            path: "/dashboard/user-attandance",
          },
          {
            title: "Reports",
            iconImage: dashboardIcon,
            path: "/dashboard/reports",
          },
        ]
      : []),
    ...(role === WEB_ROLES["Branch Head"]
      ? [
          {
            title: "Users Attendance",
            iconImage: dashboardIcon,
            path: "/dashboard/user-attandance",
          },
          {
            title: "Reports",
            iconImage: dashboardIcon,
            path: "/dashboard/reports",
          },
        ]
      : []),
    ...(role === WEB_ROLES["West and South Head"]
      ? [
          {
            title: "Users Attendance",
            iconImage: dashboardIcon,
            path: "/dashboard/user-attandance",
          },
          {
            title: "Reports",
            iconImage: dashboardIcon,
            path: "/dashboard/reports",
          },
        ]
      : []),
  ];

  const renderList = () =>
    list.map((item, index) => <LiComp {...item} key={index} />);

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <img src={logo} loading="lazy" />
      </div>
      <div className={styles.details}>
        <img />
        <p>
          <strong>{user?.name || "-"}</strong>
        </p>
        <p>{user?.email || "-"}</p>
      </div>

      <div className={styles.listings}>
        <ul>
          <li className={styles.text_labels}>
            <text>Dashboard</text>
            <text>The Indian Express</text>
          </li>
          {renderList()}
        </ul>
      </div>
    </div>
  );
};

export default SideNav;
