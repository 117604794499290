export const addNDays = (days: number, addTo?: Date) => {
  var d = addTo || new Date();
  var ts = d.getTime();
  var days = ts + days * 24 * 60 * 60 * 1000;
  return new Date(days);
};

export const subtractNDays = (days: number, subtractTo?: Date) => {
  var d = subtractTo || new Date();
  var ts = d.getTime();
  var days = ts - days * 24 * 60 * 60 * 1000;
  return new Date(days);
};
