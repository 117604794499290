import {
  faCheck,
  faCheckCircle,
  faPen,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ROLE_VS_PERMISSIONS, WEB_ROLES } from "../../../constants/roles";
import { ReactComponent as ToolTip } from "../../../assets/icons/Tooltip.svg";
import { useFormState, useQuery } from "../../../hooks";
import {
  getCollectionList,
  getUnsoldReturnList,
  STATUS,
  updateCollection,
  updateRecordStatus,
  updateUnsoldReturns,
} from "../../../state/actions/dashboard.action";
import useAuthStore from "../../../state/stores";
import Button from "../../base/Button";
import Input from "../../base/Input";
import Modal from "../../base/Modal";
import PageHeader from "../../base/PageHeader";
import Select from "../../base/Select";
import Table from "../../helper/Table/";
import { ITableConfig } from "../../helper/Table/Table";
import CollectionForm from "../../widgets/CollectionForm";
import UnsoldForm from "../../widgets/UnsoldForm";

import styles from "./dashboardScreen.module.scss";
import {
  getCenters,
  getParcelDepots,
  getRegions,
} from "../../../state/actions/masters.action";

const addNDays = (days: number, addTo?: Date) => {
  var d = addTo || new Date();
  var ts = d.getTime();
  var days = ts + days * 24 * 60 * 60 * 1000;
  return new Date(days);
};

const subtractNDays = (days: number, subtractTo?: Date) => {
  var d = subtractTo || new Date();
  var ts = d.getTime();
  var days = ts - days * 24 * 60 * 60 * 1000;
  return new Date(days);
};
const DashboardScreen = () => {
  const { user } = useAuthStore();

  const role: WEB_ROLES = user?.role;
  const userID: string = user?.id;

  const [pageState, updateState] = useFormState<{
    selectedView: string;
    fromDate: Date;
    toDate: Date;
    categories: string[];
    series: { name: string; data: any[]; type: string }[];
    showModal: boolean;
    selectedRow: any;
    showFilters: boolean;
    selectedRegion: string;
    selectedCenter: string;
    selectedDepot: string;
    filtersApplied: boolean;
  }>({
    selectedView: "unsold_return",
    fromDate: subtractNDays(7),
    toDate: new Date(),
    categories: [],
    series: [],
    showModal: false,
    selectedRow: {},
    showFilters: false,
    selectedRegion: "",
    selectedCenter: "",
    selectedDepot: "",
    filtersApplied: false,
  });

  const { exec, data } = useQuery();
  const { exec: updateExec, loading } = useQuery();

  const { exec: execRegion, data: reagionData } = useQuery();
  const { exec: execCenter, data: centerData } = useQuery();
  const { exec: execParcel, data: parcelData } = useQuery();

  const handleStatusClick = (status: STATUS, id: string | number) => () => {
    exec(() =>
      updateRecordStatus({
        approval_status: status,
        id,
        module: pageState.selectedView as "unsold_return" | "collection",
      }).then(() => {
        fetchListing();
      })
    );
  };

  const unsoldTableConfig: ITableConfig[] = [
    {
      header: "Submitted By",
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      accessor: "user_name_created_by",
      sortable: true,
    },
    {
      header: "Date",
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      accessor: "publication_date",
      sortable: true,
    },
    {
      header: "Parcel Point/Depot",
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      cellRenderer: (rowData: any) => {
        return `${rowData.parcel_depot_name} (${rowData.ship_to_code}-${rowData.user_name})`;
      },
      sortable: true,
    },

    {
      header: "Publication",
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      accessor: "publication_name",
      sortable: true,
    },

    ...(ROLE_VS_PERMISSIONS[role]?.supply.view
      ? [
          {
            header: "Supply",
            headerClassName: styles.tableHeader,
            cellClassName: styles.tableCell,
            accessor: "total_supply",
            sortable: true,
          },
        ]
      : []),
    ...(ROLE_VS_PERMISSIONS[role]?.unsold_return?.view
      ? [
          {
            header: "Unsold",
            headerClassName: styles.tableHeader,
            cellClassName: styles.tableCell,
            accessor: "unsold",
            sortable: true,
          },
        ]
      : []),
    ...(ROLE_VS_PERMISSIONS[role]?.unsold_return?.view
      ? [
          {
            header: "Returns",
            headerClassName: styles.tableHeader,
            cellClassName: styles.tableCell,
            accessor: "supply_return",
            sortable: true,
          },
        ]
      : []),
    ...(ROLE_VS_PERMISSIONS[role]?.nps
      ? [
          {
            header: "NPS",
            headerClassName: styles.tableHeader,
            cellClassName: styles.tableCell,
            cellRenderer: (data: any) => {
              const { total_supply, unsold, supply_return } = data;
              return (
                parseInt(total_supply) -
                parseInt(unsold) -
                parseInt(supply_return)
              );
            },
            sortable: true,
          },
        ]
      : []),
    ...(ROLE_VS_PERMISSIONS[role]?.physical_return.view
      ? [
          {
            header: "Physical Return",
            headerClassName: styles.tableHeader,
            cellClassName: styles.tableCell,
            accessor: "physical_return",
            sortable: true,
          },
        ]
      : []),
    {
      header: "Status",
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      cellRenderer: (rowData: any) => {
        if (rowData.approval_status == 0)
          return <div className={styles.pending}>Pending</div>;
        if (rowData.approval_status == 1)
          return <div className={styles.success}>Approved</div>;
        if (rowData.approval_status == 2)
          return <div className={styles.error}>Rejected</div>;
      },
    },
    ...(ROLE_VS_PERMISSIONS[role]?.editTable.unsold
      ? [
          {
            header: "Edit",
            headerClassName: styles.tableHeader,
            cellClassName: styles.tableCell,
            cellRenderer: (rowData: any) => {
              if (role === "Dispatch Executive" || rowData.approval_status == 0)
                return (
                  <FontAwesomeIcon
                    className={styles.editIcon}
                    icon={faPen}
                    onClick={() => {
                      updateState({
                        showModal: true,
                        selectedRow: rowData,
                      });
                    }}
                  />
                );

              return null;
            },
          },
        ]
      : []),
    ...(ROLE_VS_PERMISSIONS[role]?.showUnsoldApprovals
      ? [
          {
            header: "Actions",
            headerClassName: styles.tableHeader,
            cellClassName: styles.tableCell,
            cellRenderer: (rowData: any) => {
              if (rowData.approval_status == 0)
                return (
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <FontAwesomeIcon
                      onClick={handleStatusClick(
                        STATUS.APPROVE,
                        pageState.selectedView === "unsold_return"
                          ? rowData.unsold_return_id
                          : rowData.collection_id
                      )}
                      size="2x"
                      style={{ color: "green", cursor: "pointer" }}
                      icon={faCheckCircle}
                    />
                    <FontAwesomeIcon
                      onClick={handleStatusClick(
                        STATUS.REJECT,
                        pageState.selectedView === "unsold_return"
                          ? rowData.unsold_return_id
                          : rowData.collection_id
                      )}
                      size="2x"
                      style={{ color: "red", cursor: "pointer" }}
                      icon={faTimesCircle}
                    />
                  </div>
                );
              return null;
            },
          },
        ]
      : []),
  ];
  const collectionTableConfig: ITableConfig[] = [
    {
      header: "Submitted By",
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      accessor: "user_name_created_by",
      sortable: true,
    },
    {
      header: "Code",
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      accessor: "bill_to_code",
      sortable: true,
    },
    {
      header: "Date",
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      accessor: "bill_till_date",
      sortable: true,
    },
    {
      header: "Parcel Point/Depot",
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      cellRenderer: (rowData: any) => {
        return `${rowData.parcel_depot_name} (${rowData.ship_to_code}-${rowData.user_name})`;
      },
      sortable: true,
    },
    ...(ROLE_VS_PERMISSIONS[role]?.outstanding_collection.view
      ? [
          {
            header: "Outstanding (₹)",
            headerClassName: styles.tableHeader,
            cellClassName: styles.tableCell,
            accessor: "closing_balance",
            sortable: true,
          },
          {
            header: "Collected (₹)",
            headerClassName: styles.tableHeader,
            cellClassName: styles.tableCell,
            accessor: "amount_collected",
            sortable: true,
          },
        ]
      : []),
    {
      header: "Payment Mode",
      headerClassName: styles.tableHeader,
      cellClassName: [styles.tableCell, styles.caps].join(" "),
      accessor: "payment_mode",
      sortable: true,
    },
    {
      header: "Status",
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      cellRenderer: (rowData: any) => {
        if (rowData.approval_status == 0)
          return <div className={styles.pending}>Pending</div>;
        if (rowData.approval_status == 1)
          return <div className={styles.success}>Approved</div>;
        if (rowData.approval_status == 2)
          return <div className={styles.error}>Rejected</div>;
      },
    },
    ...(ROLE_VS_PERMISSIONS[role]?.editTable.collection
      ? [
          {
            header: "Edit",
            headerClassName: styles.tableHeader,
            cellClassName: styles.tableCell,
            cellRenderer: (rowData: any) => {
              if (rowData.approval_status == 0)
                return (
                  <FontAwesomeIcon
                    className={styles.editIcon}
                    icon={faPen}
                    onClick={() => {
                      updateState({
                        showModal: true,
                        selectedRow: rowData,
                      });
                    }}
                  />
                );
              return null;
            },
          },
        ]
      : []),

    ...(ROLE_VS_PERMISSIONS[role]?.showCollectionApprovals
      ? [
          {
            header: "Actions",
            headerClassName: styles.tableHeader,
            cellClassName: styles.tableCell,
            cellRenderer: (rowData: any) => {
              if (rowData.approval_status == 0)
                return (
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <FontAwesomeIcon
                      onClick={handleStatusClick(
                        STATUS.APPROVE,
                        pageState.selectedView === "unsold_return"
                          ? rowData.unsold_return_id
                          : rowData.collection_id
                      )}
                      size="2x"
                      style={{ color: "green", cursor: "pointer" }}
                      icon={faCheckCircle}
                    />
                    <FontAwesomeIcon
                      onClick={handleStatusClick(
                        STATUS.REJECT,
                        pageState.selectedView === "unsold_return"
                          ? rowData.unsold_return_id
                          : rowData.collection_id
                      )}
                      size="2x"
                      style={{ color: "red", cursor: "pointer" }}
                      icon={faTimesCircle}
                    />
                  </div>
                );
              return null;
            },
          },
        ]
      : []),
  ];
  const options: ApexCharts.ApexOptions = {
    chart: {
      id: "basic-bar",
      type: "bar",
      toolbar: {
        export: {
          csv: {
            headerCategory: "Date",
          },
        },
      },
    },

    noData: {
      text: "No Data Available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    grid: {},

    xaxis: {
      type: "datetime",
      categories: pageState.categories,
      tickAmount: "dataPoints",
      axisTicks: { show: true },
    },
    markers: {
      size: 2,
      strokeWidth: 2,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: [0.85, 1, 0.2],
      gradient: {
        inverseColors: true,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },

    stroke: {
      curve: ["straight", "straight", "smooth"],
      width: [3, 3, 2],
      // colors: ["#00e396", ""],
    },
  };

  const fetchListing = (withFilter: boolean = true) => {
    const funcToCall =
      pageState.selectedView === "unsold_return"
        ? getUnsoldReturnList
        : getCollectionList;
    if (pageState.selectedDepot && withFilter)
      exec(() =>
        funcToCall(
          pageState.fromDate,
          pageState.toDate,
          pageState.selectedDepot
        )
      );
    else exec(() => funcToCall(pageState.fromDate, pageState.toDate));
  };

  useEffect(() => {
    execRegion(() => getRegions(userID));
  }, []);

  useEffect(() => {
    if (pageState.selectedRegion)
      execCenter(() => getCenters("", pageState.selectedRegion));

    updateState({ selectedDepot: "", selectedCenter: "" });
  }, [pageState.selectedRegion]);

  useEffect(() => {
    if (pageState.selectedCenter)
      execParcel(() => getParcelDepots(pageState.selectedCenter));

    updateState({ selectedDepot: "" });
  }, [pageState.selectedCenter]);

  // useEffect(() => {
  //   if (pageState.selectedDepot) updateState({})

  // }, [pageState.selectedDepot]);

  useEffect(() => {
    fetchListing();
  }, [pageState.selectedView, pageState.fromDate, pageState.toDate]);

  useEffect(() => {
    if (!data) return;
    const accData = data.reduce(
      (acc: any, curr: any) => {
        if (pageState.selectedView === "unsold_return") {
          acc.cat.push(`${curr.publication_date} GMT`);
          acc.nps.push();
          acc.returns.push(curr.supply_return);
          acc.total.push(curr.total_supply);
          acc.unsold.push(curr.unsold);
        } else {
          acc.cat.push(`${curr.bill_till_date} GMT`);
          acc.amtCollected.push(curr.amount_collected);
          acc.closingBalance.push(curr.closing_balance);
        }

        return acc;
      },

      {
        cat: [],
        nps: [],
        returns: [],
        unsold: [],
        total: [],
        amtCollected: [],
        closingBalance: [],
      }
    );
    const series =
      pageState.selectedView === "unsold_return"
        ? [
            ...(ROLE_VS_PERMISSIONS[role]?.unsold_return.view
              ? [
                  {
                    name: "Returns",
                    data: accData.returns,
                    type: "line",
                  },
                  {
                    name: "Unsold",
                    data: accData.unsold,
                    type: "line",
                  },
                ]
              : []),
            ...(ROLE_VS_PERMISSIONS[role]?.supply.view
              ? [
                  {
                    name: "Total Supply",
                    data: accData.total,
                    type: "area",
                  },
                ]
              : []),
          ]
        : [
            ...(ROLE_VS_PERMISSIONS[role]?.outstanding_collection.view
              ? [
                  {
                    name: "OutStanding",
                    data: accData.closingBalance,
                    type: "line",
                  },
                  {
                    name: "Amount Collected",
                    data: accData.amtCollected,
                    type: "line",
                  },
                ]
              : []),
          ];

    updateState({ series: series, categories: accData.cat });
  }, [data, pageState.selectedView]);

  const closeModal = () => {
    updateState({
      showModal: false,
      selectedRow: {},
    });
  };

  const handleSubmit = (type: "unsold" | "collection") => (data: any) => {
    const funcToExec =
      type === "collection" ? updateCollection : updateUnsoldReturns;
    updateExec(() =>
      funcToExec(data).then(() => {
        fetchListing();
        closeModal();
      })
    );
  };

  const onApplyFilters = () => {
    updateState({ filtersApplied: true });
    updateState({ showFilters: false });
    fetchListing();
  };

  const onClearFilters = () => {
    updateState({
      filtersApplied: false,
      selectedCenter: "",
      selectedDepot: "",
      selectedRegion: "",
    });
    fetchListing(false);
  };

  return (
    <>
      <PageHeader
        header={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "1rem" }}> Dashboard</div>
            <Select
              multi={false}
              className={styles.select}
              optionAccessor="label"
              onChange={(valObj, id) =>
                updateState({ selectedView: id as string })
              }
              selectedId={pageState.selectedView}
              options={[
                { label: "Unsold / Returns", id: "unsold_return" },
                { label: "Collections", id: "collection" },
              ]}
            />
            <div>
              <Button
                onClick={() =>
                  updateState({ showFilters: !pageState.showFilters })
                }
                className={[
                  styles.tooltip,
                  pageState.filtersApplied && styles.active,
                ].join(" ")}
              >
                <ToolTip />
              </Button>
              {pageState.showFilters && (
                <div
                  className={[
                    styles.filter_section,
                    pageState.showFilters && styles.show,
                  ].join(" ")}
                >
                  <Select
                    placeholder="Select Region"
                    name="selectedRegion"
                    multi={false}
                    className={styles.select}
                    optionAccessor="name"
                    onChange={updateState}
                    selectedId={pageState.selectedRegion}
                    options={reagionData || []}
                  />
                  <Select
                    multi={false}
                    name="selectedCenter"
                    disabled={!pageState.selectedRegion}
                    placeholder="Select Center"
                    className={styles.select}
                    optionAccessor="name"
                    onChange={updateState}
                    selectedId={pageState.selectedCenter}
                    options={centerData || []}
                  />
                  <Select
                    multi={false}
                    name="selectedDepot"
                    disabled={!pageState.selectedCenter}
                    placeholder="Select Parcel Depot"
                    className={styles.select}
                    optionAccessor="label"
                    onChange={updateState}
                    selectedId={pageState.selectedDepot}
                    options={(parcelData || []).map((op: any) => ({
                      label: op.user_name,
                      id: op.user_id,
                    }))}
                  />
                  <div className={styles.actions}>
                    <Button onClick={onClearFilters}>Clear</Button>
                    <Button
                      onClick={onApplyFilters}
                      disabled={
                        !pageState.selectedRegion ||
                        !pageState.selectedCenter ||
                        !pageState.selectedDepot
                      }
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        }
        breadCrumb={["Dashboard"]}
        rightSection={
          <div
            style={{
              fontSize: 12,
              borderRadius: "4px",
            }}
          >
            <input
              style={{
                padding: ".4rem .6rem",
                background: "#f6f6f7",
                fontSize: 12,
                borderRadius: "4px",
                border: 0,
              }}
              placeholder="From"
              type={"date"}
              value={pageState.fromDate.toISOString().split("T")[0]}
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) => {
                updateState({ fromDate: new Date(e.currentTarget.value) });
              }}
            />{" "}
            -{" "}
            <input
              style={{
                padding: ".4rem .6rem",
                background: "#f6f6f7",
                fontSize: 12,
                borderRadius: "4px",
                border: 0,
              }}
              placeholder="To"
              type={"date"}
              value={pageState.toDate.toISOString().split("T")[0]}
              max={new Date().toISOString().split("T")[0]}
              min={addNDays(1, pageState.fromDate).toISOString().split("T")[0]}
              onChange={(e) => {
                updateState({ toDate: new Date(e.currentTarget.value) });
              }}
            />
          </div>
        }
      />

      <div>
        {/* <div
          style={{
            display: "flex",
            // alignItems: "center",
            justifyContent: "space-between",
            padding: "1rem 2rem",
          }}
        >
          <div
            style={{
              background: "#f6f6f7",
              borderRadius: "4px",
              padding: "1rem",
              paddingBottom: 0,
              flex: 1,
            }}
          >
            {" "}
            <Chart
              options={{
                ...options,
                title: {
                  text: "",
                },
              }}
              series={pageState.series}
              type="line"
              width={"100%"}
              height={200}
            />
          </div>
        </div> */}
        <div
          className={styles.table_section}
          style={{ height: "70vh", overflow: "auto" }}
        >
          <Table
            tableHeader={<h3>Total Distribution Center</h3>}
            tableData={data}
            tableConfig={
              pageState.selectedView === "unsold_return"
                ? unsoldTableConfig
                : collectionTableConfig
            }
            searchKeys={(pageState.selectedView === "unsold_return"
              ? unsoldTableConfig
              : collectionTableConfig
            ).reduce((acc, curr) => {
              if (curr.accessor) acc.push(curr.accessor as never);
              return acc;
            }, [])}
          />
        </div>
      </div>
      <Modal
        header={
          pageState.selectedView === "unsold_return" ? (
            <h3>Update Unsold Return</h3>
          ) : (
            <h3>Update Collection</h3>
          )
        }
        show={pageState.showModal}
        onClose={closeModal}
      >
        {pageState.selectedView === "unsold_return" ? (
          <UnsoldForm
            role={role}
            value={pageState.selectedRow}
            onSubmit={handleSubmit("unsold")}
          />
        ) : (
          <CollectionForm
            role={role}
            value={pageState.selectedRow}
            onSubmit={handleSubmit("collection")}
          />
        )}
      </Modal>
    </>
  );
};

export default DashboardScreen;
