import { axiosInstance } from "../axios";
import useAuthStore from "../stores";
import { setIsLoggedIn, setUser } from "../stores/auth.store";

export type TLogin = {
  login_id: string;
  password: string;
};

export const logout = () => {
  localStorage.removeItem("login_token");
  localStorage.removeItem("user_data");
  setIsLoggedIn(false);
  setUser(null);
  window.location.href = "/";
};

export const login = (payload: TLogin) => {
  return axiosInstance
    .post(
      "/auth/login",
      { ...payload, platform: "web" },
      {
        headers: {
          "Content-Type": "application/vnd.tiedn.ie.api.v1+json",
        },
      }
    )
    .then(({ data, headers, ...rest }) => {
      const { "tiedn-ie-api-authorization": token } = headers;

      localStorage.setItem("login_token", token);
      localStorage.setItem("user_data", JSON.stringify(data.data));
      useAuthStore.setState({ isLoggedIn: true, user: data.data });
      return data;
    })
    .catch((err) => {
      setIsLoggedIn(false);
      throw err;
    });
};

export const register = (payload: TLogin) => {
  return axiosInstance
    .post("/v1/auth/register", payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      setIsLoggedIn(false);
      throw err;
    });
};

export const getUserProfile = () => {
  return axiosInstance
    .get("/v1/auth/profile")
    .then((res) => {
      setIsLoggedIn(true);
      setUser(res.data.data[0]);
    })
    .catch((err) => {
      setIsLoggedIn(false);
      setUser(null);
    });
};

export const updateUser = (payload: {
  firstname: string;
  lastname: string;
}) => {
  return axiosInstance
    .put("/v1/auth/profile", payload)
    .then((res) => getUserProfile());
};
