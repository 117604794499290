import { faCross, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loadavg } from "os";
import React, { FC, ReactNode } from "react";
import styles from "./modal.module.scss";

const Modal: FC<{
  show: boolean;
  loading?: boolean;
  header: ReactNode;
  onClose: () => void;
  children?: ReactNode;
}> = ({ show, header, onClose, children, loading }) => {
  return (
    <>
      <div className={[styles.modal_wrapper, show && styles.open].join(" ")}>
        {loading && <div className={styles.loading}>Please Wait...</div>}
        <section className={styles.header}>
          {header}
          <div className={styles.close} onClick={onClose}>
            Close
            <FontAwesomeIcon icon={faTimes} size="lg" />{" "}
          </div>
        </section>
        {show && <section className={styles.body}>{children}</section>}
      </div>
      {show && <div className={styles.modal_back_drop}></div>}
    </>
  );
};

export default Modal;
