import React, { useEffect, useState } from "react";
import { useFormState, useQuery } from "../../../hooks";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { axiosInstance } from "../../../state/axios";
import { AgGridReact } from "ag-grid-react";
import { orderBy } from "lodash-es";
import {
  fetchUserAtttandance,
  fetchUsers,
  fetchUsersById,
  fetchUsersByRole,
} from "../../../state/actions/user.actions";
import PageHeader from "../../base/PageHeader";
import Select from "../../base/Select";
import {
  USER_ROLES,
  ROLE_VS_PERMISSIONS,
  WEB_ROLES,
} from "../../../constants/roles";
import useAuthStore from "../../../state/stores";
import Table from "../../helper/Table";
import { ITableConfig } from "../../helper/Table/Table";

const getDateFormat = (newDate: any) => {
  if (newDate) {
    const date = new Date(`${newDate}`);
    let day: any = date.getDate();
    day = day < 10 ? `0${day}` : day;
    let month: any = date.getMonth();
    month = month < 9 ? `0${month + 1}` : month + 1;
    const year = date.getFullYear();
    const getTime = newDate.split("T");
    return `${day}-${month}-${year} ${getTime[1]}`;
  } else {
    return "-";
  }
};
const defaultColsDef = {
  sortable: true,
  filter: true,
  wrapHeaderText: false,
};
const columnDef = [
  {
    headerName: "User Name",
    field: "name",
    filter: true,
    floatingFilter: true,
  },
  {
    headerName: "Check-In Time",
    field: "check_in_time_ist",
    filter: "agDateColumnFilter",
    floatingFilter: true,
    valueFormatter: ({ data }: any) => {
      return getDateFormat(data.check_in_time_ist);
    },
    floatingFilterComponentParams: { suppressFilterButton: true },
    filterParams: {
      suppressMenu: true,
      suppressFilter: true,
      comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue == null) {
          return 0;
        }
        var dateParts = cellValue.split("-");
        var year = Number(dateParts[0]);
        var month = Number(dateParts[1]) - 1;
        var day = Number(dateParts[2].split("T")[0]);
        var cellDate = `${year}-${month}-${day}`;
        const filterDate = `${new Date(
          filterLocalDateAtMidnight
        ).getFullYear()}-${new Date(
          filterLocalDateAtMidnight
        ).getMonth()}-${new Date(filterLocalDateAtMidnight).getDate()}`;
        if (cellDate !== filterDate) {
          return -1;
        } else if (cellDate === filterDate) {
          return 0;
        }
      },
    },
  },
  {
    headerName: "Check-In Address",
    field: "check_in_latitude",
    tooltipField: "check_in_latitude",
    cellRenderer: ({ data }: any) => {
      if (data.check_in_latitude) return data.check_in_latitude;
      return "-";
    },
    tooltipComponent: (params: any) => {
      return <div className="custom-tooltip">{params.value}</div>;
    },
  },
  {
    headerName: "Check-Out Time",
    field: "check_out_time_ist",
    filter: "agDateColumnFilter",
    floatingFilter: true,
    valueFormatter: ({ data }: any) => {
      return getDateFormat(data.check_out_time_ist);
    },
    floatingFilterComponentParams: { suppressFilterButton: true },
    filterParams: {
      suppressMenu: true,
      suppressFilter: true,
      comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
        if (cellValue == null) {
          return 0;
        }

        var dateParts = cellValue.split("-");
        var year = Number(dateParts[0]);
        var month = Number(dateParts[1]) - 1;
        var day = Number(dateParts[2].split("T")[0]);
        var cellDate = `${year}-${month}-${day}`;
        const filterDate = `${new Date(
          filterLocalDateAtMidnight
        ).getFullYear()}-${new Date(
          filterLocalDateAtMidnight
        ).getMonth()}-${new Date(filterLocalDateAtMidnight).getDate()}`;
        if (cellDate !== filterDate) {
          return -1;
        } else if (cellDate === filterDate) {
          return 0;
        }
      },
    },
  },
  {
    headerName: "Check-Out Address",
    field: "check_out_latitude",
    tooltipField: "check_out_latitude",
    cellRenderer: ({ data }: any) => {
      if (data.check_out_latitude) return data.check_out_latitude;
      return "-";
    },
    tooltipComponent: (params: any) => {
      return <div className="custom-tooltip">{params.value}</div>;
    },
  },
];
export const UserAttandance = () => {
  const [formState, handleInputChange, resetState, setInitialState, dirty] =
    useFormState<{
      selectedRole: string;
      selectedUser: string;
      userMap: Record<string, any>;
      selectedRegion: string;
    }>({
      selectedRole: "",
      selectedUser: "",
      userMap: {},
      selectedRegion: "",
    });
  const { user } = useAuthStore();
  const user_role: WEB_ROLES = user?.role;
  const [gridApi, setSetGridApi] = useState<any>();
  const [usersListing, setUsersListing] = useState<any>([]);
  const [rowData, setRowData] = useState<any>([]);
  const { loading, exec, data } = useQuery();
  const { exec: userListExecutor, data: userList = [] } = useQuery();
  const userDetails: any = localStorage.getItem("user_data");
  const details = JSON.parse(userDetails);
  const [regionDropdownOption, setRegionDropdownOption] = useState<any[]>([]);
  useEffect(() => {
    const roleIds: any = [];
    usersListing.map(({ id }: any) => roleIds.push(id));
    const finalData = data?.filter((item: any) => {
      return roleIds.includes(item.user_id);
    });
    setRowData(finalData);
  }, [data]);
  const handleRegion = ({ selectedRegion }: any) => {
    handleInputChange({
      selectedUser: "",
      selectedRole: "",
      selectedRegion,
    });
    setUsersListing([]);
    setRowData([]);
  };
  useEffect(() => {
    setUsersListing(userList);
  }, [userList]);
  const hndleRoleChange = ({ selectedRole }: any) => {
    handleInputChange({ selectedRole });
    userListExecutor(() =>
      fetchUsersByRole(details.id, selectedRole, formState.selectedRegion).then(
        (users: any) => {
          const list = users.filter((item: any) => {
            return item.roles === selectedRole;
          });
          /* handleInputChange({
          userMap: list,
        }); */
          exec(() => fetchUserAtttandance("", selectedRole));
          return list;
        }
      )
    );
  };
  const handleExport = () => {
    gridApi.exportDataAsCsv({
      processCellCallback(params: any) {
        let value = params.value;
        if (params.column.colDef.filter === "agDateColumnFilter") {
          value = getDateFormat(value);
        }
        return value === undefined ? "" : value;
      },
    });
  };
  useEffect(() => {
    axiosInstance.get("/masters/regions").then(({ data }) => {
      const option = data.data;
      let dataVal: any = [];
      option.forEach((tempData: any, index: number) => {
        let object = {
          label: tempData.description,
          id: tempData.id,
          value: tempData.id,
        };
        dataVal.push(object);
      });
      setRegionDropdownOption(dataVal);
    });
    const getUserRole: any = localStorage.getItem("user_region");
    handleInputChange({ selectedRegion: JSON.parse(getUserRole).id });
  }, []);
  useEffect(() => {
    if (formState.selectedUser)
      exec(() =>
        fetchUserAtttandance(formState.selectedUser, formState.selectedRole)
      );
  }, [formState.selectedUser]);

  const onGridReady = (params: any) => {
    params.api.sizeColumnsToFit();
    setSetGridApi(params.api);
  };
  return (
    <div>
      {" "}
      <PageHeader
        header="User Attendance"
        breadCrumb={["Dashboard", "User Attendance"]}
        rightSection={
          <div style={{ width: "42rem" }}>
            <Select
              className="userSelect"
              label="Region"
              multi={false}
              name="selectedRegion"
              selectedId={formState.selectedRegion}
              optionAccessor="label"
              onChange={handleRegion}
              options={regionDropdownOption}
              disabled={!ROLE_VS_PERMISSIONS[user_role]?.report?.regionDropdown}
            />
            <Select
              multi={false}
              className="userSelect"
              disabled={loading}
              name="selectedRole"
              selectedId={formState.selectedRole}
              label="Role"
              optionAccessor="label"
              onChange={hndleRoleChange}
              options={(USER_ROLES || []).map((role: any) => ({
                label: role,
                id: role,
              }))}
            />
            <Select
              className="userSelect"
              disabled={loading}
              name="selectedUser"
              selectedId={formState.selectedUser}
              label="User"
              optionAccessor="label"
              onChange={handleInputChange}
              options={(orderBy(usersListing, ["name"], ["asc"]) || []).map(
                (user: any) => ({
                  label: user.name,
                  id: user.id,
                })
              )}
            />
          </div>
        }
      />
      <div>
        <h3 style={{ padding: ".15rem 2rem" }}>
          {
            usersListing?.filter(
              (item: any) => item.id === formState.selectedUser
            )[0]?.name
          }
          ({formState.selectedRole})
        </h3>
        <div style={{ width: "100%", margin: "-60px 0 10px 87%" }}>
          <button onClick={handleExport}>Export</button>
        </div>
      </div>
      <div
        className="ag-theme-alpine grid_table"
        style={{ height: "62vh", padding: "0 1.7rem", margin: "0 0.4rem" }}
      >
        <AgGridReact
          rowData={rowData || []}
          columnDefs={columnDef}
          defaultColDef={defaultColsDef}
          onGridReady={onGridReady}
          tooltipShowDelay={0}
          tooltipHideDelay={3000}
        ></AgGridReact>
      </div>
    </div>
  );
};
