import React, { FC } from "react";
import "./button.module.scss";

export interface IButton
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

const Button: FC<IButton> = ({ children, ...rest }) => {
  return <button {...rest}>{children}</button>;
};

export default Button;
