export const ROLES = [
  "Regional Manager",
  "Circulation Executive",
  "Collection Executive",
  "Parcel Vendor",
  "Depot Salesman",
  "Collection Backend Executive",
  "Collection Head",
  "Operation Head",
  "Dispatch Executive",
  "Branch Head",
  "West and South Head",
  "Admin",
];
export const USER_ROLES = [
  "Regional Manager",
  "Circulation Executive",
  "Collection Executive",
  "Parcel Vendor",
  "Depot Salesman",
];
export enum WEB_ROLES {
  "Regional Manager" = "Regional Manager",
  "Collection Backend Executive" = "Collection Backend Executive",
  "Collection Head" = "Collection Head",
  "Operation Head" = "Operation Head",
  "Dispatch Executive" = "Dispatch Executive",
  "Branch Head" = "Branch Head",
  "Admin" = "Admin",
  "West and South Head" = "West and South Head",
}

export enum USER_TYPE {
  "internal" = "internal",
  "external" = "external",
}

export const ROLE_VS_PERMISSIONS: Record<
  Partial<WEB_ROLES>,
  {
    nps: boolean;
    supply: { view: boolean; edit: boolean };
    unsold_return: { view: boolean; edit: boolean };
    outstanding_collection: { view: boolean; edit: boolean };
    amount_collected: { view: boolean; edit: boolean };
    physical_return: { view: boolean; edit: boolean };
    showCollectionApprovals: boolean;
    showUnsoldApprovals: boolean;
    editTable: { unsold: boolean; collection: boolean };
    approveRejectTable: { unsold: boolean; collection: boolean };
    report: { view: boolean; regionDropdown: boolean };
  }
> = {
  [WEB_ROLES.Admin]: {
    nps: true,
    outstanding_collection: { view: true, edit: false },
    amount_collected: { view: true, edit: true },
    showUnsoldApprovals: true,
    showCollectionApprovals: true,
    supply: { view: true, edit: false },
    unsold_return: { view: true, edit: true },
    physical_return: { edit: false, view: false },
    editTable: { unsold: true, collection: true },
    approveRejectTable: { unsold: true, collection: true },
    report: { view: true, regionDropdown: true },
  },
  [WEB_ROLES["Branch Head"]]: {
    nps: true,
    outstanding_collection: { view: true, edit: false },
    amount_collected: { view: true, edit: true },
    showUnsoldApprovals: true,
    showCollectionApprovals: true,
    supply: { view: true, edit: false },
    unsold_return: { view: true, edit: true },
    physical_return: { edit: false, view: false },
    editTable: { unsold: true, collection: true },
    approveRejectTable: { unsold: true, collection: true },
    report: { view: true, regionDropdown: true },
  },
  [WEB_ROLES["Collection Backend Executive"]]: {
    nps: true,
    outstanding_collection: { view: true, edit: false },
    amount_collected: { view: true, edit: true },
    showUnsoldApprovals: true,
    showCollectionApprovals: true,
    supply: { view: true, edit: false },
    unsold_return: { view: true, edit: true },
    physical_return: { edit: false, view: false },
    editTable: { unsold: true, collection: true },
    approveRejectTable: { unsold: true, collection: true },
    report: { view: true, regionDropdown: true },
  },
  [WEB_ROLES["Collection Head"]]: {
    nps: true,
    outstanding_collection: { view: true, edit: false },
    amount_collected: { view: true, edit: true },
    showUnsoldApprovals: true,
    showCollectionApprovals: true,
    supply: { view: true, edit: false },
    unsold_return: { view: true, edit: true },
    physical_return: { edit: false, view: false },
    editTable: { unsold: true, collection: true },
    approveRejectTable: { unsold: true, collection: true },
    report: { view: true, regionDropdown: true },
  },
  [WEB_ROLES["Dispatch Executive"]]: {
    nps: false,
    outstanding_collection: { view: false, edit: false },
    amount_collected: { view: false, edit: false },
    showUnsoldApprovals: false,
    showCollectionApprovals: false,
    supply: { view: true, edit: false },
    unsold_return: { view: true, edit: false },
    physical_return: { edit: true, view: true },
    editTable: { unsold: true, collection: true },
    approveRejectTable: { unsold: true, collection: true },
    report: { view: false, regionDropdown: false },
  },
  [WEB_ROLES["Operation Head"]]: {
    nps: true,
    outstanding_collection: { view: true, edit: false },
    amount_collected: { view: true, edit: true },
    showUnsoldApprovals: true,
    showCollectionApprovals: true,
    supply: { view: true, edit: false },
    unsold_return: { view: true, edit: true },
    physical_return: { edit: false, view: false },
    editTable: { unsold: true, collection: true },
    approveRejectTable: { unsold: true, collection: true },
    report: { view: true, regionDropdown: true },
  },
  [WEB_ROLES["Regional Manager"]]: {
    nps: true,
    outstanding_collection: { view: true, edit: false },
    amount_collected: { view: true, edit: false },
    showUnsoldApprovals: true,
    showCollectionApprovals: false,
    supply: { view: true, edit: true },
    unsold_return: { view: true, edit: true },
    physical_return: { edit: false, view: false },
    editTable: { unsold: true, collection: false },
    approveRejectTable: { unsold: true, collection: true },
    report: { view: true, regionDropdown: false },
  },
  [WEB_ROLES["West and South Head"]]: {
    nps: true,
    outstanding_collection: { view: true, edit: false },
    amount_collected: { view: true, edit: true },
    showUnsoldApprovals: true,
    showCollectionApprovals: true,
    supply: { view: true, edit: false },
    unsold_return: { view: true, edit: true },
    physical_return: { edit: false, view: false },
    editTable: { unsold: true, collection: true },
    approveRejectTable: { unsold: true, collection: true },
    report: { view: true, regionDropdown: true },
  },
};
