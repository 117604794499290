import React from "react";
import PageHeader from "../../base/PageHeader";
import CollectionForm from "../../widgets/CollectionForm";

const Collection = () => {
  return (
    <div>
      {/* <PageHeader
        header="Collections"
        breadCrumb={["Dashboard", "Collection"]}
        // rightSection={<Button>Add Publication</Button>}
      />
      <CollectionForm /> */}
    </div>
  );
};

export default Collection;
