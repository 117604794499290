import { error } from "console";
import { axiosInstance } from "../axios";

export const getStates = () => {
  return axiosInstance
    .get("/masters/states", { headers: { "Content-Type": "application/json" } })
    .then(({ data }) => {
      return data.data;
    });
};
export const getCities = () => {
  return axiosInstance
    .get("/masters/cities", { headers: { "Content-Type": "application/json" } })
    .then(({ data }) => {
      return data.data;
    });
};
export const getDistricts = () => {
  return axiosInstance
    .get("/masters/districts", {
      headers: { "Content-Type": "application/json" },
    })
    .then(({ data }) => {
      return data.data;
    });
};
export const getUnMappedUsers = () => {
  return axiosInstance
    .get("/transaction/getAllUnMappedUser", {
      headers: { "Content-Type": "application/json" },
    })
    .then(({ data }) => {
      return data.data;
    });
};
export const getRegions = (userId?: string) => {
  return axiosInstance
    .get("/masters/regions", {
      headers: { "Content-Type": "application/json" },
      ...(userId ? { params: { userId } } : {}),
    })
    .then(({ data }) => {
      localStorage.setItem("user_region", JSON.stringify(data.data[0]));
      return data.data;
    });
};

export const getParcelDepots = (centerId?: string) => {
  return axiosInstance
    .get("/masters/parcel-depot", {
      headers: { "Content-Type": "application/json" },
      ...(centerId ? { params: { centerId } } : {}),
    })
    .then(({ data }) => {
      return data.data;
    });
};

export const getCenters = (userId?: string, regionId?: string) => {
  return axiosInstance
    .get("/masters/centers", {
      headers: { "Content-Type": "application/json" },
      params: {
        ...(userId ? { userId } : {}),
        ...(regionId ? { regionId } : {}),
      },
    })
    .then(({ data }) => {
      return data.data;
    });
};

export const updateUnmappedUser = (payload: any) => {
  return axiosInstance
    .post("/transaction/addAndMapUsers", payload)
    .then(({ data }) => {
      return data.code;
    })
    .catch((error) => {
      return "error";
    });
};
