import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { useEffect, useState } from "react";
import { ROLE_VS_PERMISSIONS, WEB_ROLES } from "../../../constants/roles";
import { useQuery } from "../../../hooks";
import useAuthStore from "../../../state/stores";
import { subtractNDays } from "../../../utils";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "../../base/Button";
import PageHeader from "../../base/PageHeader";
import Select from "../../base/Select";
import "./reportsPage.css";

export const ReportsPage = () => {
  const [rowData, setRowData] = useState([]);
  const [showSnack, setShowSnack] = useState(false);
  const [noData, setNoData] = useState(false);
  const [publicationDropdownOption, setPublicationDropdownOption] = useState<
    any[]
  >([]); //*
  const [
    circulationExecutiveDropdownOption,
    setCirculationExecutiveDropdownOption,
  ] = useState<any[]>([]);
  const [
    collectionExecutiveDropdownOption,
    setCollectionExecutiveDropdownOption,
  ] = useState<any[]>([]);

  const [selectedPublication, setSelectedPublication] = useState([]); //*
  const [regionDropdownOption, setRegionDropdownOption] = useState<any[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<Array<any>>([]);
  const [selectedAttendance, setSelectedAttendance] = useState<Array<string>>(
    []
  );
  // const [circulationExecutivenDropdownOption, setCirculationExecutivenDropdownOption] = useState<any[]>([]);
  const [selectedCirculationExecutive, setSelectedCirculationExecutive] =
    useState([]);
  const [selectedCollectionExecutive, setSelectedCollectionExecutive] =
    useState([]);
  const [parcelDepotDropdownOption, setParcelDepotDropdownOption] = useState(
    []
  ); ///djkwjdowdowd
  const [typeDropdownOption, setTypeDropdownOption] = useState<any[]>([]);
  const [selectedType, setSelectedType] = useState("");
  const [employeeTypeDropdownOption, setEmployeeTypeDropdownOption] = useState<
    any[]
  >([]);
  const [selectedEmployeeType, setSelectedEmployeeType] = useState("");
  const [selectedParcelDepot, setSelectedParcelDepot] = useState(""); //sdahdiajdiajdiajdiad
  const [selectedReportType, setSelectedReportType] = useState<any | null>("");
  const [columnDefs, setColumnDefs] = useState<any | null>([]);
  const [gridApi, setSetGridApi] = useState<any>();
  const [params, setParams] = useState<Record<string, any>>({
    start_date: subtractNDays(7).toISOString().split("T")[0],
    end_date: new Date().toISOString().split("T")[0],
  });
  const accept: string = "application/vnd.tiedn.ie.api.v1+json";
  // const BASE_URL = 'https://cors-anywhere.herokuapp.com/https://dmsapi.expressindia.com'
  const BASE_URL = "https://dmsapi.expressindia.com";
  const onGridReady = (params: any) => {
    setSetGridApi(params.api);
  };
  const handleExport = () => {
    console.log("export Done", gridApi);
    gridApi.exportDataAsCsv();
  };

  const [showPublication, setShowPublication] = useState<boolean>(false);
  const [showCirculationExecutive, setShowCirculationExecutive] =
    useState<boolean>(false);
  const [showExewiseCollection, setShowExewiseCollection] =
    useState<boolean>(false);
  const [showType, setShowType] = useState<boolean>(false);
  const [showEmployeeType, setShowEmployeeType] = useState<boolean>(false);
  const [showParcelDepot, setShowParcelDepot] = useState<boolean>(false);
  const [showAttendance, setShowAttendance] = useState<boolean>(false);

  /* const [columnDefs] = useState([
    { headerNAttandence", field: "Attandence" },
    { headerName: "Circulation Executive Name", field: "Collection Executive" },
    { headerName: "Depot / Parcel Point", field: "Depot / Parcel Point" },
    { headerName: "Opening Balance", field: "Opening Balance" },
    { headerName: "Security Deposit", field: "Security Deposit" },
    { headerName: "Todays Invoice", field: "Todays Invoice" },
    { headerName: "Total Amt Collected", field: "Total Amt Collected" },
    { headerName: "Todays Invoice", field: "Todays Invoice" },
    { headerName: "Total O/s", field: "Total O/s" },
    { headerName: "Txn No. ( applicable for Online )", field: "Txn No. ( applicable for Online )" },
    { headerName: "Amount Collected Cash", field: "Amount Collected Cash" },
    { headerName: "Amount Collected Online", field: "Amount Collected Online" },
  ]);
 */
  const { user } = useAuthStore();
  const role: WEB_ROLES = user?.role;

  const defaultColsDef = {
    sortable: true,
    filter: true,
    // flex: 1,
    editable: false,
    wrapHeaderText: false,
  };

  const [showDiv, setShowDiv] = useState(false);
  const { exec: circulationExec, loading: circulationExecLoading } = useQuery();

  const handleInputChange = (data: any) => {
    setSelectedReportType(data.selectedReportType);
    setRowData([]);
    /* getParcelDepotReportFields(data.selectedReportType) */
  };

  useEffect(() => {
    setReportTypeSelectedValue(selectedReportType);

    // getCirculationExecutiveReportFields();
    setSelectedRegion([]);
    setSelectedAttendance([]);

    setTypeDropdownOption([...getTypeHardCodedData()]);
    setEmployeeTypeDropdownOption([...getEmployeeTypeHardCodedData()]);
    setParcelDepotDropdownOption([]); //------xxx----

    setShowPublication(false);
    setShowCirculationExecutive(false);
    setShowType(false);
    setShowEmployeeType(false);
    setShowAttendance(false);
    setShowParcelDepot(false);
    setShowExewiseCollection(false);
    if (selectedReportType) {
      getPublicationReportFields();
      getRegionReportFields();
      switch (selectedReportType) {
        case "CIRCULATION_EXE_WISE_SUPPLY":
          setShowPublication(true);
          setShowCirculationExecutive(true);
          break;

        case "PHYSICAL_RETURN":
          setShowPublication(true);
          setShowType(true);
          break;

        case "ATTENDANCE_REPORT":
          setShowEmployeeType(true);
          setShowAttendance(true);
          setSelectedAttendance(["PRESENT", "ABSENT"]);
          break;

        case "REGION_WISE_COLLECTION":
          break;

        case "DEPOT_WISE_COLLECTION":
          setShowParcelDepot(true);
          setShowType(true);
          setSelectedType("depot");
          break;

        case "PARCEL_WISE_COLLECTION":
          setShowParcelDepot(true);
          setShowType(true);
          setSelectedType("parcel");
          break;

        case "EXECUTIVE_WISE_COLLECTION":
          setShowExewiseCollection(true);
          break;
      }
    }
  }, [selectedReportType]);

  useEffect(() => {
    if (
      rowData &&
      rowData.length > 0 &&
      selectedReportType &&
      selectedReportType !== "ATTENDANCE_REPORT"
    ) {
      let columnDef: any = [];
      let header = Object.keys(rowData[0]);
      header.forEach((tempData: any, index: number) => {
        let object = {
          headerName: tempData,
          field: tempData,
          hide: tempData === "spid" || tempData === "urpid" ? true : false,
        };
        columnDef.push(object);
      });
      setColumnDefs([...columnDef]);
    }
  }, [rowData, selectedReportType]);

  const getPublicationReportFields = () => {
    axios
      .get(BASE_URL + "/masters/publications", {
        headers: {
          Accept: accept,
          "Content-Type": accept,
          Authorization: localStorage.getItem("login_token") || "",
        },
      })
      .then((res) => {
        setPublicationDropdownOption([
          ...getPublicationDropDownOption(res.data),
        ]);
      })
      .catch(function (error) {});
  };

  /* const getParcelDepotReportFields = (reportTypeId: any) => {
    axios
      .get(BASE_URL + "/masters/parcel-depot", {
        headers: {
          Accept: accept,
          "Content-Type": accept,
          Authorization: localStorage.getItem("login_token") || "",
        },
      })
      .then((res) => {
        setParcelDepotDropdownOption([...getParcelDepotDropDownOption(res.data)])
      })
      .catch(function (error) {
      });
  } */

  const getRegionReportFields = () => {
    console.log(selectedReportType);
    const userData = localStorage.getItem("user_data") || "";
    axios
      .get(BASE_URL + "/masters/regions", {
        headers: {
          Accept: accept,
          "Content-Type": accept,
          Authorization: localStorage.getItem("login_token") || "",
        },
        params: { userId: JSON.parse(userData)?.id },
      })
      .then((res) => {
        setRegionDropdownOption([...getRegionDropDownOption(res.data)]);
        setSelectedRegion([getRegionDropDownOption(res.data)[0]?.id]);
        return getRegionDropDownOption(res.data)[0]?.id;
      })
      .then((firstId) => {
        if (firstId) {
          switch (selectedReportType) {
            case "CIRCULATION_EXE_WISE_SUPPLY":
              getCirculationExecutiveReportFields([firstId]);
              break;

            case "PHYSICAL_RETURN":
              break;

            case "ATTENDANCE_REPORT":
              break;

            case "REGION_WISE_COLLECTION":
              break;

            case "DEPOT_WISE_COLLECTION":
              getParcelDepotReportFields({
                regions: [firstId],
                type: "depot",
              });
              break;
            case "PARCEL_WISE_COLLECTION":
              getParcelDepotReportFields({
                regions: [firstId],
                type: "parcel",
              });
              break;

            case "EXECUTIVE_WISE_COLLECTION":
              getCollectionExecutiveReportFields([firstId]);
              break;
          }
        }
      })
      .catch(function (error) {});
  };

  const getCenterReportFields = (reportTypeId: any) => {
    const userData = localStorage.getItem("user_data") || "";
    axios
      .get(BASE_URL + "/masters/center", {
        headers: {
          Accept: accept,
          "Content-Type": accept,
          Authorization: localStorage.getItem("login_token") || "",
        },
        params: {
          userId: JSON.parse(userData)?.id,
          regionId: selectedRegion || "",
        },
      })
      .then((res) => {
        setRegionDropdownOption([...getRegionDropDownOption(res.data)]);
        setSelectedRegion([getRegionDropDownOption(res.data)[0]?.id]);
      })
      .catch(function (error) {});
  };

  const getCirculationExecutiveReportFields = (payload: number[]) => {
    return axios
      .post(BASE_URL + "/reports/region-wise-circulation-executive", payload, {
        headers: {
          Accept: accept,
          "Content-Type": accept,
          Authorization: localStorage.getItem("login_token") || "",
        },
      })
      .then((res) => {
        setCirculationExecutiveDropdownOption(
          getCirculationExecutiveDropDownOption(res.data)
        );
      })
      .catch(function (error) {});
  };

  const getCollectionExecutiveReportFields = (payload: number[]) => {
    return axios
      .post(BASE_URL + "/reports/region-wise-collection-executive", payload, {
        headers: {
          Accept: accept,
          "Content-Type": accept,
          Authorization: localStorage.getItem("login_token") || "",
        },
      })
      .then((res) => {
        setCollectionExecutiveDropdownOption(
          getCirculationExecutiveDropDownOption(res.data)
        );
      })
      .catch(function (error) {});
  };

  const getParcelDepotReportFields = (payload: any) => {
    return axios
      .post(BASE_URL + "/reports/region-wise-parcel-depot", payload, {
        headers: {
          Accept: accept,
          "Content-Type": accept,
          Authorization: localStorage.getItem("login_token") || "",
        },
      })
      .then((res) => {
        if (res.data.length) {
          setParcelDepotDropdownOption(
            getCirculationExecutiveDropDownOption(res.data)
          );
        } else {
          setParcelDepotDropdownOption([]);
        }
      })
      .catch(function (error) {});
  };

  const getTypeHardCodedData = () => {
    let data = [];
    let object1 = {
      label: "Depot",
      id: "depot",
      value: "depot",
    };

    let object2 = {
      label: "Parcel Point",
      id: "parcel",
      value: "parcel",
    };

    data.push(object1);
    data.push(object2);

    return data;
  };

  const getEmployeeTypeHardCodedData = () => {
    let data = [];
    let object1 = {
      label: "Internal",
      id: "internal",
      value: "internal",
    };

    let object2 = {
      label: "External",
      id: "external",
      value: "external",
    };

    data.push(object1);
    data.push(object2);

    return data;
  };

  const handleSearch = () => {
    let payload: any = {
      report_name: selectedReportType,
      start_date: params.start_date,
      end_date: params.end_date,
      regions: selectedRegion,
      circulation_executives: [],
      depot_or_parcels: selectedParcelDepot,
      employee_types: selectedEmployeeType ? [selectedEmployeeType] : [],
      publications: selectedPublication,
      attendance: selectedAttendance,
      // "point_type": "string"
    };

    if (selectedReportType === "CIRCULATION_EXE_WISE_SUPPLY")
      payload.circulation_executives = selectedCirculationExecutive;
    if (selectedReportType === "EXECUTIVE_WISE_COLLECTION")
      payload.collection_executives = selectedCollectionExecutive;
    if (selectedReportType === "PHYSICAL_RETURN") {
      payload.point_type = selectedType;
    }
    payload.collection_executives = (payload.collection_executives || []).map(
      (id: number) => id && id.toString()
    );
    payload.circulation_executives = (payload.circulation_executives || []).map(
      (id: number) => id && id.toString()
    );
    payload.depot_or_parcels = (payload.depot_or_parcels || []).map(
      (id: number) => id && id.toString()
    );
    payload.regions = (payload.regions || []).map(
      (id: number) => id && id.toString()
    );

    payload.publications = (payload.publications || []).map(
      (id: number) => id && id.toString()
    );

    axios
      .post(BASE_URL + "/reports/data", payload, {
        headers: {
          Accept: accept,
          "Content-Type": accept,
          Authorization: localStorage.getItem("login_token") || "",
        },
      })
      .then((res) => {
        setNoData(res.data.length === 0);
        if (selectedReportType === "ATTENDANCE_REPORT") {
          const rowData = res.data[0];
          const data: any = [];
          for (const key in rowData) {
            const type = rowData[key]["Employee Type"];
            if (type === selectedEmployeeType) {
              data.push(rowData[key]);
            }
            if (key === "AAAAAAA") {
              let columnDef: any = [];
              let header = Object.keys(rowData[key]);
              header.forEach((tempData: any, index: number) => {
                let object = {
                  headerName: tempData,
                  field: tempData,
                  hide:
                    tempData === "spid" || tempData === "urpid" ? true : false,
                };
                columnDef.push(object);
              });
              setColumnDefs([...columnDef]);
            }
          }
          setRowData(data);
        } else {
          setRowData(res.data);
        }
      })
      .catch(function (error) {
        setShowSnack(true);
        console.log(error);
      });
  };
  const [reportTypeList] = useState([
    { name: "Circulation Exec-wise Supply", id: "CIRCULATION_EXE_WISE_SUPPLY" },
    { name: "Physical Return", id: "PHYSICAL_RETURN" },
    { name: "Attendance Report", id: "ATTENDANCE_REPORT" },
    { name: "Regionwise Collection", id: "REGION_WISE_COLLECTION" },
    { name: "Depotwise Collection", id: "DEPOT_WISE_COLLECTION" },
    { name: "Parcelwise Collection", id: "PARCEL_WISE_COLLECTION" },
    { name: "Exe-wise Collection", id: "EXECUTIVE_WISE_COLLECTION" },
  ]);

  const [reportTypeSelectedValue, setReportTypeSelectedValue] = useState("");
  const { loading, exec, data } = useQuery();

  const getPublicationDropDownOption: any = (option: any) => {
    if (option && option.data && option.data.length > 0) {
      let data: any = [];
      option.data.forEach((tempData: any, index: number) => {
        let object = {
          label: tempData.name,
          id: tempData.id,
          value: tempData.id,
        };
        data.push(object);
      });
      return data;
    }
  };

  /* const getParcelDepotDropDownOption: any = (option: any) => {
    if (option && option.data && option.data.length > 0) {
      let data: any = []
      option.data.forEach((tempData: any, index: number) => {
        let object = {
          label: tempData.name,
          id: tempData.code,
          value: tempData.code,
        }
        data.push(object)
      })
      return data
    }
  } */

  const getRegionDropDownOption: any = (option: any) => {
    if (option && option.data && option.data.length > 0) {
      let data: any = [];
      option.data.forEach((tempData: any, index: number) => {
        let object = {
          label: tempData.description,
          id: tempData.id,
          value: tempData.id,
        };
        data.push(object);
      });
      return data;
    }
  };

  const getCirculationExecutiveDropDownOption: any = (option: any) => {
    if (option && option.length > 0) {
      let data: any = [];
      option.forEach((tempData: any, index: number) => {
        let object = {
          label: tempData.name,
          id: tempData.id,
          value: tempData.id,
        };
        data.push(object);
      });
      return data;
    }
  };
  const handleReportTypeChange = (value: any) => {
    setSelectedPublication(value.selectedPublication);
  };

  const handleRegionChange = (value: any) => {
    setSelectedRegion(value.selectedRegion);
    if (value.selectedRegion.length) {
      circulationExec(() => {
        switch (selectedReportType) {
          case "CIRCULATION_EXE_WISE_SUPPLY":
            return getCirculationExecutiveReportFields(value.selectedRegion);

          case "DEPOT_WISE_COLLECTION":
          case "PARCEL_WISE_COLLECTION":
            return getParcelDepotReportFields({
              regions: value.selectedRegion,
              type: selectedType,
            });

          case "EXECUTIVE_WISE_COLLECTION":
            return getCollectionExecutiveReportFields(value.selectedRegion);

          default:
            return Promise.resolve();
        }
      });
    } else {
      if (
        selectedReportType === "PARCEL_WISE_COLLECTION" ||
        selectedReportType === "DEPOT_WISE_COLLECTION"
      ) {
        setParcelDepotDropdownOption([]);
      }
    }
  };

  const handleCirculationExecutiveChange = (value: any) => {
    setSelectedCirculationExecutive(value.selectedCirculationExecutive);
  };

  const handleCollectionExecutiveChange = (value: any) => {
    setSelectedCollectionExecutive(value.selectedCollectionExecutive);
  };

  const handleTypeChange = (value: any) => {
    setSelectedType(value.selectedType);
    if (
      selectedReportType === "DEPOT_WISE_COLLECTION" ||
      selectedReportType === "PARCEL_WISE_COLLECTION"
    ) {
      getParcelDepotReportFields({
        regions: selectedRegion,
        type: value.selectedType,
      });
    }
  };
  const handleAttendanceChange = (value: any) => {
    setSelectedAttendance(value.attendance_flag);
  };

  const handleEmployeeTypeChange = (value: any) => {
    setSelectedEmployeeType(value.selectedType);
  };

  const handleParcelDepotChange = (value: any) => {
    setSelectedParcelDepot(value.selectedParcelDepot);
  };

  return (
    <div>
      {" "}
      <PageHeader
        header="Reports"
        breadCrumb={["Dashboard", "Reports"]}
        rightSection={
          <div style={{ width: "28rem" }}>
            <Select
              multi={false}
              disabled={loading}
              name="selectedReportType"
              selectedId={reportTypeSelectedValue}
              label="Report Type *"
              optionAccessor="label"
              onChange={(data) => handleInputChange(data)}
              options={(reportTypeList || []).map((reportType: any) => ({
                label: reportType.name,
                id: reportType.id,
                value: reportType.name,
              }))}
            />
          </div>
        }
      />
      {selectedReportType && (
        <div className="table_filter">
          <div className="filter_field">
            <label
              style={{
                display: "block",
                fontSize: "small",
                marginBottom: "0.4rem",
                fontWeight: 800,
              }}
            >
              Range
            </label>
            <div style={{ display: "flex" }}>
              <input
                style={{
                  padding: ".8rem .6rem",
                  background: "#f6f6f7",
                  fontSize: 12,
                  borderRadius: "4px",
                  border: 0,
                }}
                type={"date"}
                name="from"
                value={params.start_date}
                min={
                  subtractNDays(90, new Date(params.end_date))
                    .toISOString()
                    .split("T")[0]
                }
                max={params.end_date}
                onChange={(e) =>
                  setParams({
                    ...params,
                    start_date: new Date(e.target.value)
                      .toISOString()
                      .split("T")[0],
                  })
                }
              />
              <input
                style={{
                  padding: ".8rem .6rem",
                  background: "#f6f6f7",
                  fontSize: 12,
                  borderRadius: "4px",
                  border: 0,
                }}
                type={"date"}
                name="to"
                value={params.end_date}
                min={params.start_date}
                max={new Date().toISOString().split("T")[0]}
                onChange={(e) =>
                  setParams({
                    ...params,
                    end_date: new Date(e.target.value)
                      .toISOString()
                      .split("T")[0],
                  })
                }
              />
            </div>
          </div>

          {showPublication && (
            <div className="filter_field">
              <Select
                label="Publication *"
                multi={true}
                disabled={loading}
                name="selectedPublication"
                selectedId={selectedPublication}
                optionAccessor="label"
                onChange={(data) => handleReportTypeChange(data)}
                options={publicationDropdownOption}
              />
            </div>
          )}

          <div className="filter_field">
            <Select
              label="Region *"
              multi={true}
              disabled={!ROLE_VS_PERMISSIONS[role]?.report?.regionDropdown}
              name="selectedRegion"
              selectedId={selectedRegion ? selectedRegion : []}
              optionAccessor="label"
              onChange={handleRegionChange}
              options={regionDropdownOption}
            />
          </div>

          {showCirculationExecutive && (
            <div className="filter_field">
              <Select
                label="Circulation Exec *"
                multi={true}
                loading={circulationExecLoading}
                disabled={loading}
                name="selectedCirculationExecutive"
                selectedId={selectedCirculationExecutive}
                optionAccessor="label"
                onChange={handleCirculationExecutiveChange}
                options={circulationExecutiveDropdownOption}
              />
            </div>
          )}

          {showExewiseCollection && (
            <div className="filter_field">
              <Select
                label="Collection Exec *"
                multi={true}
                disabled={loading}
                name="selectedCollectionExecutive"
                selectedId={selectedCollectionExecutive}
                optionAccessor="label"
                onChange={handleCollectionExecutiveChange}
                options={collectionExecutiveDropdownOption}
              />
            </div>
          )}

          {showType && (
            <div className="filter_field">
              <Select
                label="Type"
                multi={false}
                disabled={
                  loading ||
                  selectedReportType === "DEPOT_WISE_COLLECTION" ||
                  selectedReportType === "PARCEL_WISE_COLLECTION"
                }
                name="selectedType"
                selectedId={selectedType}
                optionAccessor="label"
                onChange={(data) => handleTypeChange(data)}
                options={typeDropdownOption}
              />
            </div>
          )}

          {showEmployeeType && (
            <div className="filter_field">
              <Select
                label="Employee Type *"
                multi={false}
                disabled={loading}
                name="selectedType"
                selectedId={selectedEmployeeType}
                optionAccessor="label"
                onChange={(data) => handleEmployeeTypeChange(data)}
                options={employeeTypeDropdownOption}
              />
            </div>
          )}

          {showParcelDepot && (
            <div className="filter_field">
              <Select
                label="Parcel Depot *"
                multi={true}
                disabled={loading}
                name="selectedParcelDepot"
                selectedId={selectedParcelDepot}
                optionAccessor="label"
                onChange={(data) => handleParcelDepotChange(data)}
                options={parcelDepotDropdownOption}
              />
            </div>
          )}

          {showAttendance && (
            <div className="filter_field">
              <Select
                disabled
                label="Attendance Flag *"
                multi={true}
                name="attendance_flag"
                selectedId={selectedAttendance}
                optionAccessor="label"
                onChange={(data) => handleAttendanceChange(data)}
                options={[
                  { label: "Present", id: "PRESENT" },
                  { label: "Absent", id: "ABSENT" },
                ]}
              />
            </div>
          )}

          <div style={{ float: "right" }} onClick={handleSearch}>
            <Button className="searchbtn">Search</Button>
          </div>
        </div>
      )}
      {noData && (
        <div
          className="ag-theme-alpine grid_table"
          style={{
            textAlign: "center",
            padding: "3rem 0",
            fontWeight: "bold",
          }}
        >
          No Result found for selected parameters
        </div>
      )}
      {rowData && rowData.length > 0 && (
        <div
          className="ag-theme-alpine grid_table"
          style={{ height: "400px", padding: "0 1.7rem", margin: "0 0.4rem" }}
        >
          <button onClick={handleExport}>Export</button>
          <br />
          <br />
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColsDef}
            onGridReady={onGridReady}
          ></AgGridReact>
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={(e) => setShowSnack(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={(e) => setShowSnack(false)}
          severity={"error"}
        >
          Please select all the required field first.
        </MuiAlert>
      </Snackbar>
    </div>
  );
};
