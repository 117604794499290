import React, { useEffect, useState } from "react";
import Modal from "../../base/Modal";
import { useQuery } from "../../../hooks";
import Button from "../../base/Button";
import { useFormState } from "../../../hooks";
import { axiosInstance } from "../../../state/axios";
import Input from "../../base/Input";
import {
  getUnMappedUsers,
  getCenters,
  updateUnmappedUser,
} from "../../../state/actions/masters.action";
import PageHeader from "../../base/PageHeader";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import map from "../../../assets/icons/map.png";
import { AgGridReact } from "ag-grid-react";
import { USER_ROLES } from "../../../constants/roles";
import Select from "../../base/Select";

const defaultColsDef = {
  sortable: true,
  filter: true,
  wrapHeaderText: false,
};

const UnmappedUsers = () => {
  const { exec, data } = useQuery();
  const { exec: execCenter, data: centerData } = useQuery();
  const [rowData, setRowData] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [regionDropdownOption, setRegionDropdownOption] = useState<any[]>([]);
  const [centerDropdownOption, setCenterDropdownOption] = useState<any[]>([]);
  const [formState, handleInputChange, resetState, setInitialState, dirty] =
    useFormState<any>({});
  useEffect(() => {
    if (centerData) {
      let dataVal: any = [];
      centerData.map((tempData: any) => {
        let object = {
          label: tempData.name,
          id: tempData.id,
          value: tempData.id,
        };
        dataVal.push(object);
      });
      setCenterDropdownOption(dataVal);
    }
  }, [centerData]);
  useEffect(() => {
    if (formState.region_id)
      execCenter(() => getCenters("", formState.region_id));
  }, [formState.region_id]);
  const columnDef = [
    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "Bill To Code",
      field: "bill_code",
    },
    {
      headerName: "Ship To code",
      field: "ship_to_code",
    },
    {
      headerName: "Action to Mapped",
      field: "#",
      cellRenderer: ({ data }: any) => {
        return (
          <img
            src={map}
            alt="Mapped"
            onClick={() => {
              setShowModal(true);
              handleInputChange({
                name: data.name,
                bill_code: data.bill_code,
                ship_to_code: data.ship_to_code,
                id: data.id,
              });
            }}
          />
        );
      },
    },
  ];
  useEffect(() => {
    exec(() => getUnMappedUsers());
  }, []);
  useEffect(() => {
    setRowData(data);
  }, [data]);
  const onGridReady = (params: any) => {
    params.api.sizeColumnsToFit();
  };
  useEffect(() => {
    axiosInstance.get("/masters/regions").then(({ data }) => {
      const option = data.data;
      let dataVal: any = [];
      option.forEach((tempData: any, index: number) => {
        let object = {
          label: tempData.description,
          id: tempData.id,
          value: tempData.id,
        };
        dataVal.push(object);
      });
      setRegionDropdownOption(dataVal);
    });
    const getUserRole: any = localStorage.getItem("user_region");
    handleInputChange({ region_id: JSON.parse(getUserRole).id });
  }, []);
  return (
    <>
      <PageHeader
        header="Unmapped Users"
        breadCrumb={["Dashboard", "Master", "UnmappedUsers"]}
        // rightSection={<Button>Add Publication</Button>}
      />
      <div
        className="ag-theme-alpine grid_table"
        style={{ height: "68vh", padding: "0 1.7rem", margin: "20px 0.4rem" }}
      >
        <AgGridReact
          rowData={rowData || []}
          columnDefs={columnDef}
          defaultColDef={defaultColsDef}
          onGridReady={onGridReady}
          tooltipShowDelay={0}
          tooltipHideDelay={3000}
        ></AgGridReact>
      </div>
      <Modal
        header={<h3>Update Unmapped user</h3>}
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (
              !formState.roles ||
              !formState.region_id ||
              !formState.centers_id
            ) {
              alert("Please select required fields.");
            } else {
              const mapped = await updateUnmappedUser(formState);
              if (mapped !== "error") {
                exec(() => getUnMappedUsers());
                alert("User mapped successfully.");
              } else {
                alert(
                  "An error occurred while processing request. Please try again"
                );
              }
              setShowModal(false);
            }
          }}
        >
          <div style={{ display: "flex" }}>
            <Input
              disabled
              label="Name"
              style={{ flex: 1 }}
              name="name"
              onChange={handleInputChange}
              value={formState.name}
            />

            <Input
              required
              label="Email *"
              style={{ flex: 1 }}
              name="email"
              onChange={handleInputChange}
              value={formState.email}
            />
          </div>
          <div style={{ display: "flex" }}>
            <Input
              required
              label="Phone No *"
              type="number"
              style={{ flex: 1 }}
              name="phone"
              onChange={handleInputChange}
              value={formState.phone}
            />

            <Select
              multi={false}
              className="userSelect"
              name="roles"
              selectedId={formState.roles}
              label="Role *"
              optionAccessor="label"
              onChange={handleInputChange}
              options={[
                {
                  label: "Parcel Vendor",
                  id: "Parcel Vendor",
                },
                {
                  label: "Depot Salesman",
                  id: "Depot Salesman",
                },
              ]}
            />
          </div>
          <div style={{ display: "flex" }}>
            <Select
              multi={false}
              className="userSelect"
              name="region_id"
              selectedId={formState.region_id}
              label="Region *"
              optionAccessor="label"
              onChange={handleInputChange}
              options={regionDropdownOption}
            />

            <Select
              multi={false}
              className="userSelect"
              name="centers_id"
              selectedId={formState.centers_id}
              label="Centers *"
              optionAccessor="label"
              onChange={({ centers_id }) => {
                const center: any = centerDropdownOption.filter((item) => {
                  return item.id === centers_id;
                });
                handleInputChange({
                  centers_id,
                  center_name: center[0].label,
                });
              }}
              options={centerDropdownOption || []}
            />
          </div>

          <div style={{ display: "flex" }}>
            <Input
              disabled
              label="Bill to Code"
              style={{ flex: 1 }}
              name="bill_code"
              value={formState.bill_code}
              onChange={handleInputChange}
            />

            <Input
              disabled
              label="Ship to Code"
              style={{ flex: 1 }}
              name="ship_to_code"
              onChange={handleInputChange}
              value={formState.ship_to_code}
            />
          </div>

          <div
            style={{
              marginTop: "2rem",
            }}
          >
            <Button type="submit" disabled={!dirty}>
              Update
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UnmappedUsers;
