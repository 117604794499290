import React, { FC } from "react";
import styles from "./input.module.scss";

export interface IInput
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "onChange"
  > {
  label?: string;
  onChange: (obj: { [key: string]: any }) => void;
}

const Input: FC<IInput> = ({ label, onChange, ...rest }) => {
  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    onChange({ [name]: value });
  };

  return (
    <div className={styles.input_wrappe}>
      {label && <label>{label}</label>}
      <input {...rest} onChange={handleOnChange} />
    </div>
  );
};

export default Input;
