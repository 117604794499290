import React from "react";
import { faCross, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./style.css";

const ContactUs = () => {
  return (
    <div className="modal_background">
      <div className="modal_container">
        <div className={"close"}>
          <Link to="login">
            Close
            <FontAwesomeIcon icon={faTimes} size="lg" />{" "}
          </Link>
        </div>
        <div className="modal_content">
          <h3>Questions/ Grievances Redressals</h3>
          <p>
            Any complaints or feedback shall be informed to us through email. You may connect with us on{" "}
            <a href="mailto:feedback@indianexpress.com ">
              feedback@indianexpress.com 
            </a>{" "}
            with the details in email as follows:
            <br/>
            <br/>
            - full name<br/>
            - postal residential address<br/>
            - email address<br/>
            - cell number<br/>
            - feedback or explanation in 500 words<br/>
            <br/>
            In the alternate, please write to the following address with all the above:<br/>
            Feedback<br/>
            IE Online Media Services Private Ltd<br/>
            Express Building, Block B1/B<br/>
            Sector 10, Noida 201301, UP.<br/>
          </p>
          <div className="hide_modal_btn">
            {/* <button onClick={()=>{closeModal(false)}}>CLOSE</button> */}
            <Link to="login">To Login Page</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;