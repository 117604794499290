import { axiosInstance } from "../axios";
import useAuthStore from "../stores";
import { getCenters, getRegions } from "./masters.action";

export const fetchUsers = () => {
  return axiosInstance.get("/masters/users").then(({ data }) => {
    return data.data;
  });
};

export const fetchUsersById = (userID: number) => {
  return axiosInstance
    .get("/masters/users", {
      params: {
        userId: userID,
      },
    })
    .then(({ data }) => {
      return data.data;
    });
};

export const fetchUsersByRole = (
  userID: number,
  roles: string,
  region: string
) => {
  return axiosInstance
    .get("/masters/users/role", {
      params: {
        userId: userID,
        roles,
        regionId: Number(region),
      },
    })
    .then(({ data }) => {
      return data.data;
    });
};

export interface IUser {
  login_id: string;
  password: string;
  name: string;
  email: string;
  roles: string;
  type: string;
  phone: string;
  id?: string;
  add_update_location_pk_id?: number[];
}
export const updateUser = (payload: IUser) => {
  const { id, ...rest } = payload;
  return axiosInstance
    .put(`/auth/updateUser/${id || ""}`, rest)
    .then(({ data }) => {
      return data.data;
    });
};

export const createUser = (payload: IUser) => {
  return axiosInstance.post(`/auth/signup`, payload).then(({ data }) => {
    return data.data;
  });
};

export const fetchUserAtttandance = (userId: string, roles: string) => {
  return axiosInstance
    .get(`/transaction/attendance`, {
      params: {
        userId,
        roles,
      },
    })
    .then(({ data }) => {
      return data.data;
    });
};

export const getCenterOrRegionCode = (userId: string, role: string) => {
  const funcToExec =
    role === "Circulation Executive" ||
    role === "Collection Executive" ||
    role === "Parcel Vendor" ||
    role === "Depot Salesman"
      ? getCenters
      : getRegions;

  return funcToExec(userId).then((data) => data.map((d: any) => d.id));
};
