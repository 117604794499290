import create from "zustand";

export const useAuthStore = create<{
  isLoggedIn: boolean;
  user: null | Record<string, any>;
}>(() => ({
  isLoggedIn: false,
  user: null,
}));

export const setIsLoggedIn = (isLoggedIn: boolean) => {
  useAuthStore.setState({ isLoggedIn });
};

export const setUser = (user: null | Record<string, any>) => {
  useAuthStore.setState({ user });
};

export const selectRole = (state: { isLoggedIn: false; user: null }) => {
  return (state?.user || ({} as any))?.role;
};
// if (localStorage.getItem("login_token")) {
//   const user = localStorage.getItem("user_data") || JSON.stringify({});
//   setIsLoggedIn(true);
//   setUser(JSON.parse(user));
// }
