import React, { useEffect } from "react";
import { useQuery } from "../../../hooks";
import { getStates } from "../../../state/actions/masters.action";
import Button from "../../base/Button";
import PageHeader from "../../base/PageHeader";
import Table from "../../helper/Table";
import { ITableConfig } from "../../helper/Table/Table";

const config: ITableConfig[] = [
  {
    header: "ID",
    accessor: "id",
  },

  {
    header: "Name",
    accessor: "name",
  },
];

const StatesPage = () => {
  const { exec, data } = useQuery();
  useEffect(() => {
    exec(() => getStates());
  }, []);
  return (
    <>
      <PageHeader
        header="States"
        breadCrumb={["Dashboard", "Master", "States"]}
        // rightSection={<Button>Add Publication</Button>}
      />
      <Table tableData={data} tableConfig={config} searchKeys={["name"]} />
    </>
  );
};

export default StatesPage;
