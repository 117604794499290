import React, { FC, ReactNode, useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import CentersPage from "./components/pages/CentersPage";
import UnmappedUsers from "./components/pages/UnmappedUsers";
import CitiesPage from "./components/pages/CitiesPage";
import CollectionPage from "./components/pages/CollectionPage";
import Dashboard from "./components/pages/Dashboard";
import DashboardScreen from "./components/pages/DashboardScreen";
import DistrictsPage from "./components/pages/DistrictsPage";
import LoginPage from "./components/pages/LoginPage";
import RegionsPage from "./components/pages/RegionsPage";
import { ReportsPage } from "./components/pages/ReportsPage/ReportsPage";
import {
  default as PublicationPage,
  default as StatesPage,
} from "./components/pages/StatesPage";
import UnsoldPage from "./components/pages/UnsoldPage";
import { UserAttandance } from "./components/pages/UserAttandance";
import UserPage from "./components/pages/UserPage";
import AboutUs from "./components/widgets/AboutUs";
import ContactUs from "./components/widgets/ContactUs";
import PrivacyPolicy from "./components/widgets/PrivacyPolicy/PrivacyPolicy";
import ReturnPolicy from "./components/widgets/ReturnPolicy";
import Terms from "./components/widgets/TermsandConditions";
import useAuthStore from "./state/stores";

const ProtectedRoute: FC<{
  component: ReactNode;
  authType: "PRE_AUTH" | "POST_AUTH";
}> = ({ component, authType }) => {
  const { isLoggedIn } = useAuthStore();

  if (authType === "PRE_AUTH") {
    if (isLoggedIn) return <Navigate to="/" replace />;

    return <>{component}</>;
  }
  if (!isLoggedIn) return <Navigate to="/login" replace />;
  return <>{component}</>;
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="login"
          element={
            <ProtectedRoute authType="PRE_AUTH" component={<LoginPage />} />
          }
        />
        <Route
          path="refund-policy"
          element={
            <ProtectedRoute authType="PRE_AUTH" component={<ReturnPolicy />} />
          }
        />
        <Route
          path="terms"
          element={<ProtectedRoute authType="PRE_AUTH" component={<Terms />} />}
        />
        <Route
          path="privacy-policy"
          element={
            <ProtectedRoute authType="PRE_AUTH" component={<PrivacyPolicy />} />
          }
        />
        <Route
          path="contact-us"
          element={
            <ProtectedRoute authType="PRE_AUTH" component={<ContactUs />} />
          }
        />
        <Route
          path="about-us"
          element={
            <ProtectedRoute authType="PRE_AUTH" component={<AboutUs />} />
          }
        />
        <Route
          path="dashboard"
          element={
            <ProtectedRoute component={<Dashboard />} authType="POST_AUTH" />
          }
        >
          <Route path="master" element={<Outlet />}>
            <Route
              path="states"
              element={
                <ProtectedRoute
                  component={<StatesPage />}
                  authType="POST_AUTH"
                />
              }
            />
            <Route path="cities" element={<CitiesPage />} />
            <Route path="districts" element={<DistrictsPage />} />
            <Route path="regions" element={<RegionsPage />} />
            <Route path="centers" element={<CentersPage />} />
            <Route path="unmappedusers" element={<UnmappedUsers />} />
            <Route
              index
              element={<Navigate to="/dashboard/master/states" replace />}
            />
          </Route>
          <Route path="unsold" element={<UnsoldPage />} />
          <Route path="collection" element={<CollectionPage />} />
          <Route path="report" element={<LoginPage />} />
          <Route path="users" element={<UserPage />} />
          <Route path="user-attandance" element={<UserAttandance />} />
          <Route path="reports" element={<ReportsPage />} />
          <Route index element={<DashboardScreen />} />

          {/* <Route path="*" element={<Navigate to={"/dashboard"} />} /> */}
        </Route>
        <Route
          index
          element={
            <ProtectedRoute
              authType="POST_AUTH"
              component={<Navigate to="/dashboard" replace />}
            />
          }
        />
        <Route path="*" element={<Navigate to={"/login"} />} />
      </Routes>
    </BrowserRouter>
  );
  // return <Dashboard />;
}

export default App;
