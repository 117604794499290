import React from "react";
import { Outlet } from "react-router-dom";
import Select from "../../base/Select";
import TopBar from "../../base/TopBar";
import SideNav from "../../widgets/SideNav";
import styles from "./dashboard.module.scss";

const Dashboard = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.left_bar}>
        <SideNav />
      </div>
      <div className={styles.right_bar}>
        <div>
          <TopBar />
          <div
            style={{
              paddingTop: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
