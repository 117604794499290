import React from "react";
import { useFormState } from "../../../hooks";
import PageHeader from "../../base/PageHeader";
import UnsoldForm from "../../widgets/UnsoldForm";

const UnsoldPage = () => {
  useFormState({});
  return (
    <>
      <PageHeader
        header="Unsold Returns"
        breadCrumb={["Dashboard", "Unsold Returns"]}
        // rightSection={<Button>Add Publication</Button>}
      />
      {/* <UnsoldForm /> */}
    </>
  );
};

export default UnsoldPage;
